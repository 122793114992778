import React,{useState, useEffect} from "react";
import { useContext } from "react";
import GlobalContext from "./global_context";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Spinner from "react-bootstrap/Spinner"
import LoopingBanner from "./components/looping_banner";

import {API_LOGIN, API_ADMIN_LOGIN} from "../config"

//import {detectEnterKeyPress} from "./utils/utils"

import Cookies from 'universal-cookie';

import CenterContainer from "./center_container";

const errorMessages=[
    "",
    "Fields must not be empty",
    "Incorrect username or password",
    "The user account has expired",
    "Something unexpected happened"
]


const getImageWidth = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve({width: img.naturalWidth, height: img.naturalHeight});
      img.onerror = reject;
      img.src = src;
    });
  };

export default function Login(props){
    const {setLoggedIn,
        setAdminLoggedIn,
        setAdminAccount,
        setAdminPassword,
        setUserRole,
        setUserId,
        setAdminAccess,
        setClientName}=props;

    const cookies = new Cookies();

    const [authFailedType, setAuthFailedType] = useState(0); //0: Not failed; 1: Field Empty; 2: Wrong pw or username; 3: Expired
    const [username, setUsername] = useState("");
    const [password,setPassword]=useState("");

    const [loading, setLoading] = useState(false);

    const [bannerSize, setBannerSize] = useState({width:0,height:0});
    const {windowWidth} = useContext(GlobalContext);

    const bannerSrc = require(`../static/images/brand_logos/logo_1.png`);
    const bannerElement = (
        <div className="banner">
            <img src={bannerSrc} alt="brand logo"></img>
        </div>);

    useEffect(() => {
        getImageWidth(bannerSrc).then((size)=>{
            //console.log(size);
            setBannerSize(size);
        });
    }, [bannerSrc]);

    useEffect(() => {
        function handleKeyDown(event) {
            if (event.key === 'Enter') {
                login();
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };

    }, []);


    async function login(){
        //check if input is empty
        if(loading){
            return;
        }

        if(username===""||password===""){
            setAuthFailedType(1);
        }
        else{
            setLoading(true);
            const data={
                username:username,
                password:password
            }

            try {
                const res=await fetch(API_LOGIN,{
                    method:"POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })

                const resData=await res.json();

                if(resData!=="Failed login"&resData!=="Expired"&resData!=="Something went wrong"){
                    const userRole = Number(resData["role"]);
                    cookies.set('userLoggedIn', true, { path: '/',maxAge:3600} );
                    cookies.set('role', userRole, { path: '/',maxAge:3600} );
                    cookies.set('username', username, { path: '/',maxAge:3600} );
                    cookies.set('password', password, { path: '/',maxAge:3600} );
                    cookies.set('id', resData["id"], { path: '/',maxAge:3600} );
                    setUserRole(userRole);
                    setUserId(resData["id"]);
                    setClientName(resData["client_name"]);

                    //if account is admin
                    if(userRole === 1 || userRole === 2){
                        //setClientName(resData["HOL"]);
                        const data={
                            adminAccount:username,
                            adminPassword:password
                        }
                        try {
    
                            const loginRes=await fetch(API_ADMIN_LOGIN,{
                                method:"POST",
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(data),
                            })
            
                            const loginResData=await loginRes.json();
            
                            if(loginResData!=="Failed login"&loginResData!=="Something went wrong"){
                                // console.log(loginResData["access"]);
                                //console.log("admin login success");
                                setAuthFailedType(0);
                                setLoading(false);
                                setAdminAccount(username);
                                setAdminPassword(password);
                                //admin access
                                setAdminAccess(loginResData["access"])
                                setAdminLoggedIn(true);
                                cookies.set('userLoggedIn2', true, { path: '/'} );
                                cookies.set('adminAccount', username, { path: '/'} );
                                cookies.set('adminPassword', password, { path: '/'} );
                                cookies.set('adminAccess', loginResData["access"], { path: '/'} );

                                setAuthFailedType(0)
                                setLoggedIn(true);  
                               
                            }
                            else if(loginResData==="Failed login"){
                                setAuthFailedType(2);
                                cookies.set('userLoggedIn', false, { path: '/',maxAge:3600} );
                                setLoading(false);
                            }
                            else{
                                setAuthFailedType(3);
                                cookies.set('userLoggedIn', false, { path: '/',maxAge:3600} );
                                setLoading(false);
                            }
                            
                        } catch (e) {
                            console.error(e);
                            setAuthFailedType(4);
                            cookies.set('userLoggedIn', false, { path: '/',maxAge:3600} );
                            setLoading(false);
                        }

                    } 
                    else {
                        setAuthFailedType(0)
                        setLoggedIn(true);  
                    }  
                }
                //wrong pw or account
                else if(resData==="Failed login"){
                    setAuthFailedType(2);
                    setLoading(false);
                }
                //expired account
                else if(resData==="Expired"){
                    setAuthFailedType(3);
                    setLoading(false);
                }
                //probably network or server issue
                else{
                    setAuthFailedType(4);
                    setLoading(false);
                }
                
            } catch (e) {
                console.error(e);
                setAuthFailedType(4);
            }

            
        }
    }

    function loginOnEnter(e){
        //detectEnterKeyPress(e);
        if(e.key==="Enter"){
            login();
        }
    }

    return (
        <div className="w-100">
            <CenterContainer>
            <Container fluid className="d-flex flex-column" style={{height:"74vh"}}>
                <Row className="flex-grow-1">
                    <Col xs={7} className="d-flex align-items-center">
                        <div>
                            <h1 style={{fontFamily:"butlerlight",fontWeight:"300",fontSize:"100px"}}>HOL Gallery</h1>
                            <p className="tagline"> Explore how HOL can help you boost engagement for large visitor groups –
                                <br/>
                                <span style={{color:"#0E0E0E"}}>No need for guides!</span></p>
                        </div>
                    </Col>
                    <Col  xs={{span:5, offset:0}} className="d-flex flex-column justify-content-center align-items-center">
                        <span style={{textAlign:"center", fontSize:"14px"}}>This space is only available for the clients of HOL Experiences.<br/>To find out more about us, please visit our website <a href="https://hol.sg" target="_blank" rel="noreferrer noopener" className="theme-link">here</a></span>
                        <div style={{marginTop:"32px"}} className="d-flex flex-column align-items-center login-form w-100">
                            
                            <input 
                                className="input-field" 
                                type="text"
                                placeholder="Username"
                                onChange={(e)=>{setUsername(e.target.value)}}
                                value={username}
                                onKeyPress={loginOnEnter}
                            ></input>

                            <input 
                            className="input-field mt-5" 
                            type="password"
                            placeholder="Password"
                            onChange={(e)=>{setPassword(e.target.value)}}
                            value={password}
                            onKeyPress={loginOnEnter}
                            ></input>

                            <span className="error">
                                {errorMessages[authFailedType]}
                            </span>
                            
                            {
                                loading ? <Spinner animation="grow" role="status" variant="secondary" className="mt-5"></Spinner>
                                : <button className="mt-5 standard-button" onKeyPress={(e)=>{loginOnEnter(e)}} onClick={()=>{login()}}>Get Started</button>
                            }
                            
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mb-2 d-flex flex-column">
                        <span style={{fontFamily: "Open Sans", fontSize: "12px", fontWeight: 600,  textTransform:"uppercase", textAlign: "left", color:"#808080"}}>USED BY:</span>
                    </Col>
                </Row>

                </Container>
            </CenterContainer>

            <div className="banner-container mt-4">
                <LoopingBanner 
                tailPadding={110}
                speed={100}
                items={[bannerElement]} 
                itemWidth={bannerSize.width}
                itemHeight={bannerSize.height}
                desiredHeight={90}
                containerWidth={windowWidth} 
                ></LoopingBanner>
            </div>

        </div>
        
    );
}