const prefix = process.env.NODE_ENV === 'development' ? '/hol_showcase' : '';

const API_LOGIN = prefix + process.env.REACT_APP_API_LOGIN;
const API_ADMIN_LOGIN = prefix + process.env.REACT_APP_API_ADMIN_LOGIN;
const API_ADMIN_GET_USERS = prefix + process.env.REACT_APP_API_ADMIN_GET_USERS;
const API_ADMIN_CREATE_USER = prefix + process.env.REACT_APP_API_ADMIN_CREATE_USER;
const API_ADMIN_UPDATE_USER = prefix + process.env.REACT_APP_API_ADMIN_UPDATE_USER;
const API_ADMIN_DISABLE_USER = prefix + process.env.REACT_APP_API_ADMIN_DISABLE_USER;
const API_ADMIN_DELETE_USER = prefix + process.env.REACT_APP_API_ADMIN_DELETE_USER;
const API_ADMIN_DELETE_INTERACTIVE =
  prefix + process.env.REACT_APP_API_ADMIN_DELETE_INTERACTIVE;
const API_ADMIN_UPDATE_INTERACTIVE =
  prefix + process.env.REACT_APP_API_ADMIN_UPDATE_INTERACTIVE;
const API_GET_INTERACTIVE_PREVIEWS = prefix + process.env.REACT_APP_API_GET_INTERACTIVE_PREVIEWS;
const API_GET_INTERACTIVE = prefix + process.env.REACT_APP_API_GET_INTERACTIVE;
const API_GET_GIMMIES = prefix + process.env.REACT_APP_API_GET_GIMMIES;
const API_ADMIN_GET_ADMINS = prefix + process.env.REACT_APP_API_ADMIN_GET_ADMINS;
const API_ADMIN_CREATE_ADMIN = prefix + process.env.REACT_APP_API_ADMIN_CREATE_ADMIN;
const API_ADMIN_UPDATE_ADMIN = prefix + process.env.REACT_APP_API_ADMIN_UPDATE_ADMIN;
const GALLERY_STORE = prefix + process.env.REACT_APP_GALLERY_STORE;

const API_CREATE_INTERACTIVE=prefix + process.env.REACT_APP_API_CREATE_INTERACTIVE;
const API_UPLOAD_INTERACTIVES_VIDEOS =prefix + process.env.REACT_APP_API_UPLOAD_INTERACTIVES_VIDEO;

const API_UPDATE_INTERACTIVE = prefix + process.env.REACT_APP_API_UPDATE_INTERACTIVE;
const API_DELETE_INTERACTIVE = prefix + process.env.REACT_APP_API_DELETE_INTERACTIVE;
const API_CREATE_CLIENT = prefix + process.env.REACT_APP_API_CREATE_CLIENT;
const API_UPDATE_CLIENT = prefix + process.env.REACT_APP_API_UPDATE_CLIENT;
const API_DELETE_CLIENT = prefix + process.env.REACT_APP_API_DELETE_CLIENT;
const API_CREATE_CAROUSEL_ITEM = prefix + process.env.REACT_APP_API_CREATE_CAROUSEL_ITEM;
const API_DELETE_CAROUSEL_ITEM = prefix + process.env.REACT_APP_API_DELETE_CAROUSEL_ITEM;

const API_GET_INTERACTIVE_CATEGORIES = prefix + process.env.REACT_APP_API_GET_INTERACTIVE_CATEGORIES;
const API_CREATE_INTERACTIVE_CATEGORY = prefix + process.env.REACT_APP_API_CREATE_INTERACTIVE_CATEGORY;
const API_UPDATE_INTERACTIVE_CATEGORY = prefix + process.env.REACT_APP_API_UPDATE_INTERACTIVE_CATEGORY;
const API_DELETE_INTERACTIVE_CATEGORY = prefix + process.env.REACT_APP_API_DELETE_INTERACTIVE_CATEGORY;
export {
  API_LOGIN,
  API_ADMIN_LOGIN,
  API_ADMIN_GET_USERS,
  API_ADMIN_CREATE_USER,
  API_ADMIN_UPDATE_USER,
  API_ADMIN_DISABLE_USER,
  API_ADMIN_DELETE_USER,
  API_ADMIN_DELETE_INTERACTIVE,
  API_ADMIN_UPDATE_INTERACTIVE,
  API_GET_INTERACTIVE_PREVIEWS,
  API_GET_INTERACTIVE,
  API_CREATE_INTERACTIVE,
  API_GET_GIMMIES,
  API_ADMIN_GET_ADMINS,
  API_ADMIN_CREATE_ADMIN,
  API_ADMIN_UPDATE_ADMIN,
  API_UPLOAD_INTERACTIVES_VIDEOS,
  API_UPDATE_INTERACTIVE,
  API_DELETE_INTERACTIVE,
  API_CREATE_CLIENT,
  API_UPDATE_CLIENT,
  API_DELETE_CLIENT,
  API_CREATE_CAROUSEL_ITEM,
  API_DELETE_CAROUSEL_ITEM,
  API_GET_INTERACTIVE_CATEGORIES,
  API_CREATE_INTERACTIVE_CATEGORY,
  API_UPDATE_INTERACTIVE_CATEGORY,
  API_DELETE_INTERACTIVE_CATEGORY,
  GALLERY_STORE
};
