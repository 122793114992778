import React, { useEffect, useState} from 'react';

//desiredHeight is the height of the container in pixel
export default function LoopingBanner({ tailPadding = 0, speed, items, itemWidth, itemHeight, desiredHeight, containerWidth, className='', style = null}) {
    const [itemCount, setItemCount] = useState(0);
    const [duplicatedItems, setDuplicatedItems] = useState([]);
    const [realItemWidth, setRealItemWidth] = useState(0);
    const [translateX, setTranslateX] = useState(0);

    

    useEffect(() => {
        if(!itemWidth || !containerWidth) 
            return;

        const ratio = desiredHeight / itemHeight;
        setRealItemWidth(itemWidth * ratio + tailPadding);

    }, [itemWidth,itemHeight, desiredHeight]);

    useEffect(() =>{
        if(realItemWidth === 0 || containerWidth === 0) return;

        const updateItemCount = () => {
            const count = Math.ceil(containerWidth / realItemWidth) + 1;
            setItemCount(count);
            // console.log('container width:', containerWidth);
            // console.log('item width:', realItemWidth);
            // console.log('item count:', count);
        };

        updateItemCount();

        window.addEventListener('resize', updateItemCount);
        return () => window.removeEventListener('resize', updateItemCount);
    }, [containerWidth, realItemWidth])

    useEffect(()=>{
        if(itemCount === 0) return;
        const newItems = [];
        for (let i = 0; i < itemCount; i++) {
            newItems.push(
            <div style={{marginRight:`${tailPadding}px`}} key={i}>
                {items[i % items.length]}
            </div>
            );
        }
        setDuplicatedItems(newItems);
    },[itemCount])

    useEffect(() => {
        let animationFrameId;
        let lastTimestamp = performance.now();

        const updateTranslateX = (timestamp) => {
            const deltaTime = timestamp - lastTimestamp;
            lastTimestamp = timestamp;

            setTranslateX(prevTranslateX => {
                let newTranslateX = prevTranslateX - (speed * deltaTime) / 1000;
                if (Math.abs(newTranslateX) >= realItemWidth) {
                    newTranslateX += realItemWidth;
                }
                return newTranslateX;
            });

            animationFrameId = requestAnimationFrame(updateTranslateX);
        };

        animationFrameId = requestAnimationFrame(updateTranslateX);

        return () => cancelAnimationFrame(animationFrameId);
    }, [speed, realItemWidth]);

    return (
        <div className={`looping-banner ${className}`.trim()} style={style}>
            <div className="looping-banner-content" style={{transform:`translateX(${translateX}px)`,height:`${desiredHeight}px`}}>
                {duplicatedItems}
            </div>
        </div>
    );
};
