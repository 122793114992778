import React, {useRef, useState, useEffect} from "react";
import Carousel from "react-bootstrap/Carousel";
import {phone_frame} from '../../static/images';
import {GALLERY_STORE} from '../../config'

export default function MobileMockup(props){
    const { className = '', style = {}, width = "auto", height = "auto", items = [], activeIndex = 0, setActiveIndex = null } = props
    const [carouselSize, setCarouselSize] = useState({ width: 0, height: 0 });

    const imageRef = useRef(null);

    const handleSelect = (selectedIndex, e) => {
        setActiveIndex(selectedIndex);
    };

    useEffect(() => {
        //console.log(items);
        if (imageRef.current) {
            setCarouselSize({
                width: imageRef.current.clientWidth - 10,
                height: imageRef.current.clientHeight
            });

        }
    }, [items]);

    return (
        <div className={`mobile-carousel-wrapper ${className}`.trim()}>
            <img style={{width:width, height:height}} ref={imageRef} className="mobile-frame" src={phone_frame} alt="Mobile Frame"/>
            {
                items.length > 0 ? (
                <Carousel className="mobile-carousel" 
                    style = {{ ...style, width: carouselSize.width, height: carouselSize.height }}
                    interval={null}
                    {...(setActiveIndex ? {activeIndex, onSelect: handleSelect} : {})}
                    prevIcon={<span className="fas fa-chevron-left chevron" ></span>} // Custom previous icon
                    nextIcon={<span className="fas fa-chevron-right chevron"></span>} // Custom next icon
                    indicators={true}
                    >
                        {items.map((item, index) => (
                            <Carousel.Item key={index}>
                                {item.url_type === "image" && (
                                    <img
                                        src={`${GALLERY_STORE}${item.url}`}
                                        alt={`Slide ${index}`}
                                    />
                                )}
                                {item.url_type === "Youtube" && (
                                    <iframe
                                        src={`${item.url}?rel=0&iv_load_policy=3`}
                                        title={`YouTube player ${index}`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                                        allowFullScreen
                                    ></iframe>
                                )}
                                {item.url_type === "iframe" && (
                                    <iframe
                                        src={item.url}
                                        title={`Iframe ${index}`}
                                        frameBorder="0"
                                    ></iframe>
                                )}
                            </Carousel.Item>
                        ))}
                    </Carousel>) : 
                    // this is placeholder to keep the layout height
                    (<div className="mobile-carousel" 
                        style = {{ ...style, width: carouselSize.width, height: carouselSize.height }}>    
                    </div>)
            }
            

            
            
        </div>
    );
}