import React from 'react';
import { Link } from 'react-router-dom';
import { half_diamond } from '../../static/images';
export default function Navigator(props){
    const { routes, className = '', style = {}} = props;

    return (
    <div className={`${className}`.trim()} style={style}>
        {routes.map((route, index) => (
            <span key={index}>
                <Link className="theme-label" to={route.path}>{route.text}</Link>
                {index < routes.length - 1 && <img src={half_diamond} alt=" < " />}
            </span>
        ))}
    </div>);
    
}