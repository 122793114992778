import React from 'react';

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  return (
    <div style={{ backgroundColor: '#f9f9f9', padding: '4px' }}>
      <nav>
        <ul className='pagination justify-content-center'>
          <li className='page-item'>
            <a
              className='page-link'
              onClick={prevPage}
              href='#'
              style={{ color: '#4B4D52', backgroundColor: '#f9f9f9', border: 'none', marginRight: '10px' }}
            >
              {'<'}
            </a>
          </li>
          {pageNumbers.map((pgNumber) => (
            <li key={pgNumber} className={`page-item ${currentPage == pgNumber ? 'active' : ''} `}>
              <a
                onClick={() => setCurrentPage(pgNumber)}
                className='page-link'
                href='#'
                style={{
                  color: `${currentPage == pgNumber ? 'white' : '#4B4D52'}`,
                  backgroundColor: `${currentPage == pgNumber ? '#4B4D52' : '#f9f9f9'}`,
                  border: 'none',
                  borderRadius: '5px',
                  marginRight: '10px',
                }}
              >
                {pgNumber}
              </a>
            </li>
          ))}
          <li className='page-item'>
            <a
              className='page-link'
              onClick={nextPage}
              href='#'
              style={{ color: '#4B4D52', backgroundColor: '#f9f9f9', border: 'none', marginRight: '10px' }}
            >
              {'>'}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
