import React, { createContext, useEffect, useState} from 'react';


const GlobalContext = createContext();

export function GlobalContextProvider({children, interactiveCategories, refreshInteractiveCategories}) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth);
        });

        return () => {
            window.removeEventListener('resize', () => {
                setWindowWidth(window.innerWidth);
            });
        }
    },[])
    
    return (
        <GlobalContext.Provider value={{ interactiveCategories, refreshInteractiveCategories, windowWidth }}>
            {children}
        </GlobalContext.Provider>
    );
};


export default GlobalContext;