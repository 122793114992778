import React, { useState} from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button, InputGroup, Row, Col, Alert } from 'react-bootstrap';
import {InteractiveData, ClientData, CarouselItemTypes} from "./interactive_data"
import { API_CREATE_INTERACTIVE } from "../../config"
import Cookies from 'universal-cookie';
import CenterContainer from '../center_container';
import Container from 'react-bootstrap/Container';
import ConfirmModal from '../components/confirm_modal';
import UploadImageContainer from '../components/upload_image_container';
import { createLocalUrl, isValidYouTubeEmbed } from '../utils/utils';
import CategoryManagementDropdown from '../components/category_management_dropdown';

// Assuming the above classes are in the same file or imported



function CarouselItemForm({ id, item, index, onChange, onRemove }) {
  const handleItemChange = (field, value) => {
    const updatedItem = { ...item, [field]: value };
    if(field === 'type'){
        updatedItem.content = null;
    }
    onChange(index, updatedItem);
  };



  return (
      <Col xs={4}>
        <Form.Group controlId={`carouselType-${index}`}>
          <Form.Label>Type</Form.Label>
          <Form.Control
            as="select"
            value={item.type}
            onChange={(e) => {handleItemChange('type', e.target.value);}}
          >
              {CarouselItemTypes.map((option, index) => {
                  return <option key={index} value={option}>{option}</option>
              })}

          </Form.Control>
        </Form.Group>

        {item.type === 'image' && (
          <UploadImageContainer 
          label="Image" 
          accept='image/*' 
          imgData={item.content && item.content.url} 
          id={id}
          onChangeImg={(e) => {
            const file = e.target.files ? e.target.files[0] : null;
            const url = createLocalUrl(file);
            handleItemChange('content', { file: file, url: url });
          }}
            />
        )}

        {item.type === 'Youtube' && (
          <Form.Group controlId={`carouselYouTube-${index}`}>
            <Form.Label>YouTube Embedded URL</Form.Label>
            <Form.Control
              type="text"
              value={item.content || ''}
              onChange={(e) => handleItemChange('content', e.target.value)}
              placeholder="Enter YouTube embedded URL"
              isInvalid={item.content && !isValidYouTubeEmbed(item.content)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid YouTube embedded URL e.g., https://www.youtube.com/embed/VIDEO_ID (without query like ?si=...).
            </Form.Control.Feedback>
          </Form.Group>
        )}

        {item.type === 'iframe' && (
          <Form.Group controlId={`carouselIframe-${index}`}>
            <Form.Label>iFrame URL</Form.Label>
            <Form.Control
              type="text"
              value={item.content || ''}
              onChange={(e) => handleItemChange('content', e.target.value)}
              placeholder="Enter iFrame URL"
            />
          </Form.Group>
        )}

        <Button variant="danger" onClick={() => onRemove(index)}>
          Remove Item
        </Button>
      
      </Col>
  );
}

function CarouselForm({ id, carouselItems, setCarouselItems, name = 'Carousel' }) {
  const handleAddItem = () => {
    setCarouselItems([...carouselItems, { type: 'image', content: null }]);
  };

  const handleItemChange = (index, updatedItem) => {
    const newItems = [...carouselItems];
    newItems[index] = updatedItem;
    setCarouselItems(newItems);
  };

  const handleRemoveItem = (index) => {
    setCarouselItems(carouselItems.filter((_, i) => i !== index));
  };

  return (
    <div className="mb-4" style={{padding:"20px",backgroundColor:'#d8e3e3'}}>
      <h5>{name}</h5>
      <Button variant="primary" onClick={handleAddItem}>
        Add Carousel Item+
      </Button>
      <Row className="mt-2">
      {carouselItems.map((item, index) => (
        <CarouselItemForm
        key={`carousel-item-${index}`}
            id={`${id}-${index}`}
          item={item}
          index={index}
          onChange={handleItemChange}
          onRemove={handleRemoveItem}
        />
      ))}
      </Row>
    </div>
  );
}

function InteractiveForm() {
  const cookies = new Cookies();
  const [data, setData] = useState(new InteractiveData());
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [uploading, setUploading] = useState(false);

  const history = useHistory();

  const updateData = (updatedData) => {
    setData(InteractiveData.fromObject(updatedData));
  }

  const handleClientChange = (index, field, value) => {
    const updatedClient = { ...data.clients[index], [field]: value };
    const newClients = [...data.clients];
    newClients[index] = updatedClient;
    updateData({ ...data, clients: newClients });
  };

  const handleAddClient = () => {
    const newClient = new ClientData();
    data.addClient(newClient);
    updateData(data);
  };

  const handleRemoveClient = (index) => {
    data.removeClient(index);
    updateData(data);
  };

  const handleCarouselChange = (newCarousel) => {
    data.carousel = newCarousel;
    updateData(data);
  };

  const confirmDeleteClient = (index) => {
    setDeleteIndex(index);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    if (deleteIndex !== null) {
      handleRemoveClient(deleteIndex);
      setDeleteIndex(null);
    }
    setShowDeleteModal(false);
  };

  const uploadForm = () => {
    if(uploading)
        return;
    setUploading(true);
    const formData = data.createFormData();
    formData.append('adminAccount', cookies.get('adminAccount'));
    formData.append('adminPassword', cookies.get('adminPassword'));

//
    fetch(API_CREATE_INTERACTIVE, {
        method: 'POST',
        body: formData,
    })
    .then(response => response.json())
    .then(data => {
        if(!data.success)
            throw new Error(data.message);

        history.push('/home');
    })
    .catch(error => {
        console.error('Error:', error);
        setErrorMsg('Upload failed, check console for more information');
    })
    .finally(() => {
      setUploading(false);
    });
    ;
  };

  return (
    <>
    <CenterContainer>
      <Container className='side-padded'>
      <Form>
        <Row>
          <Col>
          <CategoryManagementDropdown
            value={data.type}
            onChange={(newVal) => updateData({ ...data, type: newVal })}
          />
          {/* <Form.Group controlId="type">
            <Form.Label>Category</Form.Label>

            <Form.Control
              as="select"
              value={data.type}
              onChange={(e) => updateData({ ...data, type: e.target.value })}
            >
                {interactiveCategories.map((option, index) => {
                  return <option key={index} value={option}>{option}</option>
                })}
            </Form.Control>
          </Form.Group> */}
          </Col>
        </Row>


        <Row>
          <Col className='d-flex'>
            <UploadImageContainer 
          label="Thumbnail" 
          id="thumbnailImg" 
          accept='image/*' 
          imgData={data.thumbnail && data.thumbnail.url} 
          onChangeImg={(e) => {
            const file = e.target.files ? e.target.files[0] : null;
            const url = createLocalUrl(file);
              updateData({ ...data, thumbnail: {file: file, url: url} })
          }} />

          <UploadImageContainer 
          className='ml-5'
          label="Preview Gif" 
          id="previewGif" 
          accept='image/*' 
          imgData={data.previewGif && data.previewGif.url} 
          onChangeImg={(e) => {
            const file = e.target.files ? e.target.files[0] : null;
            const url = createLocalUrl(file);
              updateData({ ...data, previewGif: {file: file, url: url} })
          }} />

          </Col>
        </Row>


      {/* <Form.Group controlId="thumbnail">
        <Form.Label>Thumbnail</Form.Label>
        {data.thumbnail && <img src={data.thumbnail} alt="Thumbnail Preview" style={{ maxHeight: '100px', marginBottom: '10px' }} />}
        <Form.Control
          type="file"
          accept="image/*"
          onChange={(e) => updateData({ ...data, thumbnail: createLocalUrl(e.target.files[0]) })}
        />
      </Form.Group> */}

      {/* <Form.Group controlId="previewGif">
        <Form.Label>Preview Gif</Form.Label>
        <Form.Control
          type="file"
          accept="image/gif"
          onChange={(e) => updateData({ ...data, previewGif: createLocalUrl(e.target.files[0]) })}
        />
      </Form.Group> */}

      <Form.Group controlId="interactiveName">
        <Form.Label>Interactive Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter interactive name"
          value={data.interactiveName}
          onChange={(e) => updateData({ ...data, interactiveName: e.target.value })}
        />
      </Form.Group>

      <Form.Group controlId="description">
        <Form.Label>What is this about?</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Describe the content"
          value={data.description}
          onChange={(e) => updateData({ ...data, description: e.target.value })}
        />
      </Form.Group>

      <Row>
        <Col xs={12}>
          <Form.Group controlId="accessibilityFeatures">
            <Form.Label>Accessibility Features</Form.Label>
            {data.accessibilityFeatures.map((feature, index) => (
            <InputGroup key={index} className="mb-2">
              <Form.Control
                type="text"
                value={feature}
                onChange={(e) => {
                  const updatedFeatures = [...data.accessibilityFeatures];
                  updatedFeatures[index] = e.target.value;
                  updateData({ ...data, accessibilityFeatures: updatedFeatures });
                }}
                placeholder="Enter accessibility feature"
              />
              <InputGroup.Append>
                <Button
                  variant="danger"
                  onClick={() => {
                    data.removeFeature(index);
                    updateData({ ...data });
                  }}
                >
                  Remove
                </Button>
              </InputGroup.Append>
            </InputGroup>
          ))}
          <br/>
          <Button
            variant="primary"
            onClick={() => {
              data.addFeature('');
              updateData({ ...data });
            }}
          >
            Add+
          </Button>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="assetsRequired">
          <Form.Label>Assets Required</Form.Label>
          {data.assetsRequired.map((asset, index) => (
            <InputGroup key={index} className="mb-2">
              <Form.Control
                type="text"
                value={asset}
                onChange={(e) => {
                  const updatedAssets = [...data.assetsRequired];
                  updatedAssets[index] = e.target.value;
                  updateData({ ...data, assetsRequired: updatedAssets });
                }}
                placeholder="Enter required asset"
              />
            <InputGroup.Append>
              <Button
                variant="danger"
                onClick={() => {
                  data.removeAsset(index);
                  updateData({ ...data });
                }}
              >
                Remove
              </Button>
            </InputGroup.Append>
          </InputGroup>
        ))}
        <br/>
        <Button
          variant="primary"
          onClick={() => {
            data.addAsset('');
            updateData({ ...data });
          }}
        >
          Add+
        </Button>
        </Form.Group>
      </Col>
    </Row>
      
    <Row>
      <Col>
      <Form.Group controlId={`assetDetails`}>
      <Form.Label>Assets Descriptions</Form.Label>
          <Row>
          {data.assetDetails.map((asset, index) => {
            return (
              <Col xs={6} xl={4} key={`asset-info-${index}`}>
                <InputGroup className="mb-2 d-flex flex-column w-100">
                  
                    <Form.Control
                      className='w-100'
                      type="text"
                      value={asset.name}
                      onChange={(e) => {
                        const updatedAssets = [...data.assetDetails];
                        updatedAssets[index] = { ...updatedAssets[index], name: e.target.value };
                        updateData({ ...data, assetDetails: updatedAssets });
                      }}
                      placeholder={`Enter asset name`}
                    />
                    <Form.Control
                      className='w-100 mt-2'
                      type="text"
                      as="textarea"
                      value={asset.description}
                      onChange={(e) => {
                        const updatedAssets = [...data.assetDetails];
                        updatedAssets[index] = { ...updatedAssets[index], description: e.target.value };
                        updateData({ ...data, assetDetails: updatedAssets });
                      }}
                      placeholder={`Enter description`}
                    />
                  

                  <InputGroup.Append>
                  <Button
                    className='mt-2'
                    variant="danger"
                    onClick={() => {
                      data.removeAssetDetail(index);
                      updateData({ ...data });
                    }}
                  >
                    Remove
                  </Button>
                </InputGroup.Append>
                </InputGroup>
              </Col>
            );
          })}
          </Row>


          <Button
            variant="primary"
            onClick={() => {
              data.addAssetDetail({name: '', description: ''});
              console.log(JSON.stringify(data.assetDetails));
              updateData({ ...data });
            }}
          >
            Add Asset Description+
          </Button>

        </Form.Group>
        </Col>

        <Col xs={12}>
          <UploadImageContainer 
              label="Gimme Instruction Image" 
              id="gameImg" 
              accept='image/*' 
              imgData={data.gameImage && data.gameImage.url} 
              onChangeImg={(e) => {
                const file = e.target.files ? e.target.files[0] : null;
                const url = createLocalUrl(file);
                updateData({ ...data, gameImage: {file: file, url: url}})
              }} />
        </Col>
      </Row>

      <CarouselForm
          id="GimmeCarousel"
          name="Gimme Carousel" 
          carouselItems={data.carousel}
          setCarouselItems={(items) => handleCarouselChange(items)}
      />

      {data.clients.map((client, index) => (
        <ClientForm
          key={`client-${index}`}
          clientIndex={index}
          clientData={client}
          onChange={(field, value) => handleClientChange(index, field, value)}
          onRemove={() => confirmDeleteClient(index)}
        />
      ))}
      <Button variant="primary" className='mt-3' onClick={handleAddClient}>
        Add Client+
      </Button>

      <br/>

      <div className='w-100 d-flex justify-content-center'>
        <Button className='mt-3 mb-3 standard-button' variant="success" type="submit"  onClick={(e)=>{e.preventDefault();setShowSubmitModal(true);setErrorMsg('');}}>
          Submit
        </Button>
      </div>


      </Form>
      </Container>
    </CenterContainer>


    <ConfirmModal text="Are you sure you want to delete this client?" show={showDeleteModal} onHide={() => setShowDeleteModal(false)} onConfirm={handleConfirmDelete} />
    <ConfirmModal text="Are you sure you want to submit?" error={errorMsg} show={showSubmitModal} onHide={() => setShowSubmitModal(false)} onConfirm={uploadForm} processing={uploading} />
    </>
    
  );
}

function ClientForm({ clientIndex, clientData, onChange, onRemove }) {
  return (
    <div className='mt-3' style={{backgroundColor:"#e3e0d8", padding:"20px"}}>
      <Row>
        <Col>
        <Form.Group controlId="clientName">
          <Form.Label>Client Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter client name"
            value={clientData.name}
            onChange={(e) => onChange('name', e.target.value)}
          />
        </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="targetAudience">
            <Form.Label>Target Audience</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter target audiences"
              value={clientData.targetAudiences}
              onChange={(e) => onChange('targetAudiences', e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>


    <UploadImageContainer 
        label="Client Logo" 
        id={`clientLogo-${clientIndex}`} 
        accept='image/*' 
        imgData={clientData.logo && clientData.logo.url} 
        onChangeImg={(e) => {
          const file = e.target.files ? e.target.files[0] : null;
          const url = createLocalUrl(file);
          onChange('logo', {file: file, url: url});
        }} 
        />
    {/* <Form.Group controlId="clientLogo">
      <Form.Label>Client Logo</Form.Label>
      <Form.Control
        type="file"
        onChange={(e) => onChange('logo', e.target.files[0])}
      />
    </Form.Group> */}



    <Form.Group controlId="templateUsage">
      <Form.Label>Template Usage</Form.Label>
      <Form.Control
        as="textarea"
        type="text"
        placeholder="Enter template usage"
        value={clientData.templateUsage}
        onChange={(e) => onChange('templateUsage', e.target.value)}
      />
    </Form.Group>

    <Form.Group as={Row}>
      <Col>
        <Form.Label>Time to Develop</Form.Label>
        <Form.Control
          type="number"
          placeholder="Enter value"
          value={clientData.timeToDevelop.value}
          onChange={(e) =>
            onChange('timeToDevelop', { ...clientData.timeToDevelop, value: e.target.value })
          }
        />
      </Col>
      <Col>
        <Form.Label>Unit</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter unit"
          value={clientData.timeToDevelop.unit}
          onChange={(e) =>
            onChange('timeToDevelop', { ...clientData.timeToDevelop, unit: e.target.value })
          }
        />
      </Col>
    </Form.Group>

    <Form.Group as={Row}>
      <Col>
        <Form.Label>Dwell Time</Form.Label>
        <Form.Control
          type="number"
          placeholder="Enter value"
          value={clientData.dwellTime.value}
          onChange={(e) =>
            onChange('dwellTime', { ...clientData.dwellTime, value: e.target.value })
          }
        />
      </Col>
      <Col>
        <Form.Label>Unit</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter unit"
          value={clientData.dwellTime.unit}
          onChange={(e) =>
            onChange('dwellTime', { ...clientData.dwellTime, unit: e.target.value })
          }
        />
      </Col>
    </Form.Group>

    <Form.Group controlId="interactionsRegistered">
      <Form.Label>Interactions Registered</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter interactions registered"
        value={clientData.interactionsRegistered}
        onChange={(e) => onChange('interactionsRegistered', e.target.value)}
      />
    </Form.Group>

    
    <CarouselForm
        id={`ClientCarousel-${clientIndex}`}
        name={`Client Carousel: ${clientData.name}`}
        carouselItems={clientData.carousel}
        setCarouselItems={(items) => onChange('carousel', items)}
    />


      <Button variant="danger" onClick={onRemove}>
        Remove Client
      </Button>
    </div>
  );
}

export default InteractiveForm;
