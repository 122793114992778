import React from 'react';


export default function UploadImageContainer({label, imgData, onChangeImg, accept='.png', id, className=''}) {
    return (
    <div className={`${className} upload-images-container`.trim()}>
        <label>{label}</label>
        <div className='upload-media-wrapper'>
        <div className='image-item' style={{ display: imgData === null ? 'none' : undefined }}>
            <img className='' src={imgData} alt='preview' />
            <button className='remove-btn' onClick={(e) => {e.preventDefault(); onChangeImg(e);}}>
            <i className='bi bi-x-circle-fill'></i>
            </button>
        </div>
        <label
            className='add-btn'
            htmlFor={`${label}-${id}`}
            style={{
            display: imgData === null ? 'block' : 'none',
            cursor: 'pointer',
            }}
        >
        <i className='bi bi-plus-lg'></i>
        </label>
        <input id={`${label}-${id}`} type='file'onChange={onChangeImg} accept={accept} />
        </div>
    </div>
    )
}