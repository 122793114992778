import React, { useState, useEffect } from 'react';
//import {Link,useHistory} from "react-router-dom"
import Cookies from 'universal-cookie';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

//import Footer from './components/Footer';
import DropdownCheckbox from './components/dropdown_checkbox';

import { magnifier, black_cross } from '../static/images';

//API urls
import {
  API_ADMIN_GET_USERS,
  API_ADMIN_CREATE_USER,
  API_ADMIN_UPDATE_USER,
  API_ADMIN_DISABLE_USER,
  API_ADMIN_DELETE_USER,
  API_GET_INTERACTIVE_PREVIEWS,
} from '../config';
import Pagination from './components/pagination';

//get today's date
const d = new Date();
export default function Client(props) {
  const { adminAccount, adminPassword } = props;

  const cookies = new Cookies();

  const [today, setToday] = useState(
    `${d.getFullYear()}-${d.getMonth() >= 9 ? '' : '0'}${d.getMonth() + 1}-${
      d.getDate() >= 10 ? '' : '0'
    }${d.getDate()}`
  );

  const [input, setInput] = useState('');

  const [allUsers, setAllUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);

  const [tableRows, setTableRows] = useState([]);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const currentRecords = tableRows.slice(indexOfFirstRecord, indexOfLastRecord);

  const nPages = Math.ceil(tableRows.length / recordsPerPage);

  //for create account modal
  const [createClientName, setCreateClientName] = useState('HOL');
  const [createUsername, setCreateUsername] = useState('');
  const [createPassword, setCreatePassword] = useState('');
  const [createExDate, setCreateExDate] = useState('');
  const [createRole, setCreateRole] = useState('');
  const [createFieldEmpty, setCreateFieldEmpty] = useState(false);

  const [editClientName, setEditClientName] = useState('');
  const [editId, setEditId] = useState('');
  const [editUsername, setEditUsername] = useState('');
  const [editPassword, setEditPassword] = useState('');
  const [editExDate, setEditExDate] = useState('');
  const [editFieldEmpty, setEditFieldEmpty] = useState(false);

  const [disableId, setDisableId] = useState('');
  const [disableUsername, setDisableUsername] = useState('');

  const [deleteId, setDeleteId] = useState('');
  const [deleteUsername, setDeleteUsername] = useState('');

  const [processing, setProcessing] = useState(false);

  //const cookiesLoggedIn = cookies.get('userLoggedIn') === 'true'; //check cookie if the user is logged in

  //interactives data
  const [interactiveData, setInteractiveData] = useState([]);

  //get interactives from database
  async function getInteractives() {

    const formData = new FormData();

    formData.append('username', adminAccount);
    formData.append('password', adminPassword)

    const res = await fetch(API_GET_INTERACTIVE_PREVIEWS, {
      method: 'POST',
      body: formData,
    });

    const resData = await res.json().catch((error) => console.log('An error occured: ', error));

    if (resData) {
      const reformattedData = resData.map((interactive) => {
        return {
          id: interactive.id,
          interactive_name: interactive.interactive_name ? interactive.interactive_name : 'Interactive ' + interactive.id + ' (Unnamed)',
        };
      });

      setInteractiveData(reformattedData);
      //console.log(reformattedData);
    }
  }

  //for selecting interactives
  const [selectedInteractive, setSelectedInteractive] = useState([]);
  const [selectedInteractiveName, setSelectedInteractiveName] = useState([]);
  const [showAllInteractives, setShowAllInteractives] = useState(false);
  //when the page is loaded, automatically run if the two states are set
  useEffect(() => {
    if (adminAccount && adminPassword) {
      fetchUsersData();
      getInteractives();
    }
  }, []);

  useEffect(() => {
    //This will force UI to update when interactives are fetched
  },[interactiveData]);

  //update selected interactives to display to user
  useEffect(() => {
    if (selectedInteractive.length === 0) {
      setSelectedInteractiveName(['Select Interactives']);
    } else {
      let interactiveName = [];
      for (let i = 0; i < selectedInteractive.length; i++) {
        if (selectedInteractive.length === 1) {
          interactiveName.push(interactiveData[selectedInteractive[i]].interactive_name);
        } else {
          interactiveName.push(interactiveData[selectedInteractive[i]].interactive_name + ', ');
        }
      }
      setSelectedInteractiveName(interactiveName);
    }
  }, [interactiveData,selectedInteractive]);

  function generateUsername() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    let string = '_' + Math.random().toString(36).substr(2, 9);

    return string;
  }

  function generatePassword() {
    let length = 11,
      charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
      string = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      string += charset.charAt(Math.floor(Math.random() * n));
    }

    return string;
  }

  //select interactives
  function selectInteractive(index) {
    const newSelected = [...selectedInteractive];
    newSelected.push(index);
    setSelectedInteractive(newSelected);
  }

  function unSelectInteractive(index) {
    let newSelected = [...selectedInteractive];
    newSelected = newSelected.filter((e) => {
      return e !== index;
    });
    setSelectedInteractive(newSelected);
  }

  function selectAllInteractives() {
    if (showAllInteractives) {
      setSelectedInteractive([]);
      setShowAllInteractives(false);
    } else {
      setSelectedInteractive([]);
      setShowAllInteractives(true);
    }
  }

  function appendTimeToDate(date) {
    const d = `${date} 23:59:59`;

    return d;
  }

  function currentTimeStamp() {
    const d = new Date();
    const td = `${d.getFullYear()}-${d.getMonth() >= 9 ? '' : '0'}${d.getMonth() + 1}-${
      d.getDate() >= 10 ? '' : '0'
    }${d.getDate()}`;
    const time = d.toTimeString().split(' ')[0];

    const currentTimeStamp = td + ' ' + time;
    return currentTimeStamp;
  }

  function openCreateModal() {
    setCreateUsername('');
    setCreatePassword('');
    setCreateExDate('');
    setCreateFieldEmpty(false);
    setSelectedInteractive([]);
    setShowAllInteractives(false);
    setShowCreateModal(true);
  }

  function openEditModal(index) {
    const user = displayedUsers[index];
    setEditClientName(user.client_name);
    setEditId(user.id);
    setEditUsername(user.username);
    setEditPassword(user.password);
    setEditExDate(user.expiration_date.split(' ')[0]);
    setEditFieldEmpty(false);
    //set interactives for update modal
    if (user.interactives === '') {
      setShowAllInteractives(true);
    } 
    else {
      const userCurrentInteractives = user.interactives.split(',').map(Number);
      //convert interactive id to index in all interactives array
      //console.log(interactiveData);
     // console.log(userCurrentInteractives);
      const interactiveIndices = [];
      for (let i = 0; i < interactiveData.length; ++i){
        if(userCurrentInteractives.includes(interactiveData[i].id)){
          interactiveIndices.push(i);
        }
      }

      setSelectedInteractive(interactiveIndices);
      setShowAllInteractives(false);
    }
    setShowEditModal(true);
  }

  function openDisableModal(index) {
    setDisableId(displayedUsers[index].id);
    setDisableUsername(displayedUsers[index].username);
    setShowDisableModal(true);
  }

  function openDeleteModal(index) {
    setDeleteId(displayedUsers[index].id);
    setDeleteUsername(displayedUsers[index].username);
    setShowDeleteModal(true);
  }

  async function fetchUsersData() {
    const data = {
      adminAccount: adminAccount,
      adminPassword: adminPassword,
    };
    const res = await fetch(API_ADMIN_GET_USERS, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const resData = await res.json();

    if (resData) {
      //console.log(resData);
      setAllUsers(resData);
    }
  }

  async function createNewUser() {
    if (createUsername && createPassword && createExDate && !processing) {
      setProcessing(true);

      if (selectedInteractive.length !== 0 || showAllInteractives) {
        const data = {
          clientName: createClientName,
          adminAccount: adminAccount,
          adminPassword: adminPassword,
          username: createUsername,
          userPassword: createPassword,
          expirationDate: appendTimeToDate(createExDate),
          interactives: selectedInteractive.map((index) => interactiveData[index].id),
          role: 0,
        };

        const res = await fetch(API_ADMIN_CREATE_USER, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        const resData = await res.json();

        if (resData === 'Success') {
          setShowCreateModal(false);
          fetchUsersData();
        }
      } else {
        setCreateFieldEmpty(true);
      }
    } else {
      setCreateFieldEmpty(true);
    }
    setProcessing(false);
  }

  async function updateUserDetails() {
    if (editUsername && editPassword && editExDate && !processing) {
      setProcessing(true);

      if (selectedInteractive.length !== 0 || showAllInteractives) {
        const data = {
          adminAccount: adminAccount,
          adminPassword: adminPassword,
          id: editId,
          username: editUsername,
          userPassword: editPassword,
          expirationDate: appendTimeToDate(editExDate),
          interactives: selectedInteractive.map((index) => interactiveData[index].id),
          clientName: editClientName,
        };

        const res = await fetch(API_ADMIN_UPDATE_USER, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        const resData = await res.json();

        if (resData === 'Success') {
          setShowEditModal(false);
          fetchUsersData();
        }
      } else {
        setEditFieldEmpty(true);
      }
    } else {
      setEditFieldEmpty(true);
    }
    setProcessing(false);
  }

  async function disableUser() {
    if (disableId && !processing) {
      setProcessing(true);

      const data = {
        adminAccount: adminAccount,
        adminPassword: adminPassword,
        id: disableId,
      };

      const res = await fetch(API_ADMIN_DISABLE_USER, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const resData = await res.json();
      if (resData === 'Success') {
        setShowDisableModal(false);
        setDisableId('');
        setDisableUsername('');
        fetchUsersData();
      }
    }

    setProcessing(false);
  }

  async function deleteUser() {
    if (deleteId && !processing) {
      setProcessing(true);

      const data = {
        adminAccount: adminAccount,
        adminPassword: adminPassword,
        id: deleteId,
      };

      const res = await fetch(API_ADMIN_DELETE_USER, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const resData = await res.json();
      if (resData === 'Success') {
        setShowDeleteModal(false);
        setDeleteId('');
        setDeleteUsername('');
        fetchUsersData();
      }
    }
    setProcessing(false);
  }

  function filterUsersByInput(usersArr) {
    let newArr = [];

    for (let i = 0; i < usersArr.length; i++) {
      if (usersArr[i].id === input) {
        newArr.push(usersArr[i]);
      } else if (usersArr[i].username.toLowerCase().includes(input.toLowerCase())) {
        newArr.push(usersArr[i]);
      }
    }

    return newArr;
  }

  useEffect(() => {
    if (allUsers.length > 0) {
      let filteredUsers = [...allUsers];

      if (input !== '') {
        filteredUsers = filterUsersByInput(filteredUsers);
      }

      setDisplayedUsers(filteredUsers);
    }
  }, [input, allUsers]);

  useEffect(() => {
    const now = Date.now();

    const rows = displayedUsers.map((value, index) => {
      let d = Date.parse(value.expiration_date);
      let expired = now > d;
      //console.log(value);

      return (
        <tr key={`row-id-${value.id}`}>
          <td className='text'>{index + 1}</td>
          <td className='text'>{value.id}</td>
          <td className='text'>{value.client_name}</td>
          <td className='text'>{value.username}</td>
          <td className='text'>{value.password}</td>
          <td className='text'>{value.creation_date}</td>
          <td className={`text ${expired ? 'expired' : ''}`}>{value.expiration_date}</td>
          <td className='text'>{value.interactives !== '' ? value.interactives : 'Access to all'}</td>
          <td
            onClick={() => {
              openEditModal(index);
            }}
            className='icon'
          >
            <i className='fas fa-pen'></i>
          </td>
          <td
            onClick={() => {
              openDisableModal(index);
            }}
            className='icon'
          >
            <i className='fas fa-ban'></i>
          </td>
          <td
            onClick={() => {
              openDeleteModal(index);
            }}
            className='icon'
          >
            <i className='fas fa-trash-alt'></i>
          </td>
        </tr>
      );
    });

    setTableRows(rows);
  }, [displayedUsers]);

  return (
    <div className='client-container'>
      <Container fluid>

        <Row className='table-section'>
          <Col xs={12} className='table-container'>
            <Row>
              <Col xs='auto'>
                <h2 className='header'>Client Accounts</h2>
              </Col>
              <Col className='d-flex justify-content-end'>
                <div className='search-box'>
                  <input
                    onChange={(e) => {
                      setInput(e.target.value);
                    }}
                    placeholder='Search for accounts...'
                  ></input>
                  <img alt='magnifier' src={magnifier}></img>
                </div>

                <button
                  onClick={() => {
                    openCreateModal();
                  }}
                  className='grey-btn ml-3'
                >
                  Create New Account
                </button>
              </Col>
            </Row>

            <Row className='mt-5'>
              <Col xs={12}>
                <Table responsive className='account-table' striped borderless>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Account ID.</th>
                      <th>Client Name</th>
                      <th>Username</th>
                      <th>Password</th>
                      <th>Date of Creation</th>
                      <th>Date of Expiration</th>
                      <th>Accessible Interactives (ID)</th>
                      <th className='icon-col'>Edit</th>
                      <th className='icon-col'>Disable</th>
                      <th className='icon-col'>Delete</th>
                    </tr>
                  </thead>
                  <tbody>{currentRecords}</tbody>
                </Table>
                <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
              </Col>
            </Row>
          </Col>

          {showCreateModal || showEditModal || showDisableModal || showDeleteModal || showResultModal ? (
            <div className='modal-wrapper'>
              {showCreateModal ? (
                <Container className='create-container'>
                  <Row className='position-relative'>
                    <div
                      onClick={() => {
                        setShowCreateModal(false);
                      }}
                      className='close-cross-btn'
                    >
                      <img alt='close' src={black_cross}></img>
                    </div>
                    <Col xs={12}>
                      <h2 className='header'>Create New Account</h2>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col xs={12}>
                      <label>Client Name</label>
                      <div className='input-box'>
                        <input
                          value={createClientName}
                          onChange={(e) => {
                            setCreateClientName(e.target.value);
                          }}
                          placeholder='Set a client name...'
                        ></input>
                      </div>
                    </Col>
                    <Col className='mt-3' xs={12}>
                      <label>Username</label>
                      <div className='input-box'>
                        <input
                          value={createUsername}
                          onChange={(e) => {
                            setCreateUsername(e.target.value);
                          }}
                          placeholder='Set a username...'
                        ></input>
                        <i
                          onClick={() => {
                            setCreateUsername(generateUsername());
                          }}
                          className='shuffle-icon fas fa-random'
                        ></i>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col xs={12}>
                      <label>Password</label>
                      <div className='input-box'>
                        <input
                          placeholder='Set a password...'
                          value={createPassword}
                          onChange={(e) => {
                            setCreatePassword(e.target.value);
                          }}
                        ></input>
                        <i
                          onClick={() => {
                            setCreatePassword(generatePassword());
                          }}
                          className='shuffle-icon fas fa-random'
                        ></i>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col xs={12}>
                      <label>Date of expiration</label>
                      <div className='input-box'>
                        <input
                          type='date'
                          placeholder='Set a date...'
                          value={createExDate}
                          onChange={(e) => {
                            setCreateExDate(e.target.value);
                          }}
                          min={today}
                        ></input>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col xs={12}>
                      <label>
                        Interactives to showcase (Selected:{' '}
                        {showAllInteractives ? interactiveData.length : selectedInteractive.length})
                      </label>
                      <div className='input-dropdown'>
                        <DropdownCheckbox
                          search={true}
                          className='select-interactive'
                          options={interactiveData.map((interactive) => interactive.interactive_name)}
                          menuText={selectedInteractiveName}
                          //"Select Interactives"
                          onCheck={selectInteractive}
                          onUncheck={unSelectInteractive}
                          selected={selectedInteractive}
                          disable={showAllInteractives}
                        />
                      </div>
                      <div
                        key='all'
                        className={`item ${showAllInteractives ? 'checked' : ''}`}
                        onClick={() => selectAllInteractives()}
                      >
                        <div className='checkbox'>
                          <i className='fas fa-check'></i>
                        </div>
                        <div className='item-name'>Showcase all interactives</div>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-5'>
                    <Col xs={12} className='d-flex justify-content-center'>
                      <button
                        onClick={() => {
                          createNewUser();
                        }}
                        className='grey-btn'
                      >
                        Create New Account
                      </button>
                    </Col>
                  </Row>
                  {createFieldEmpty ? (
                    <Row className='mt-1'>
                      <Col className='error text-center' xs={12}>
                        Fields must not be empty
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                </Container>
              ) : (
                <></>
              )}

              {showEditModal ? (
                <Container className='edit-container'>
                  <Row className='position-relative'>
                    <div
                      onClick={() => {
                        setShowEditModal(false);
                      }}
                      className='close-cross-btn'
                    >
                      <img alt='close' src={black_cross}></img>
                    </div>
                    <Col xs={12}>
                      <h2 className='header'>Edit</h2>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col xs={12}>
                      <label>Client Name</label>
                      <div className='input-box'>
                        <input
                          value={editClientName}
                          onChange={(e) => {
                            setEditClientName(e.target.value);
                          }}
                          placeholder='Set a client name...'
                        ></input>
                      </div>
                    </Col>

                    <Col className='mt-3' xs={12}>
                      <label>Username</label>
                      <div className='input-box'>
                        <input
                          placeholder='Set a username...'
                          onChange={(e) => {
                            setEditUsername(e.target.value);
                          }}
                          value={editUsername}
                        ></input>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col xs={12}>
                      <label>Password</label>
                      <div className='input-box'>
                        <input
                          placeholder='Set a password...'
                          onChange={(e) => {
                            setEditPassword(e.target.value);
                          }}
                          value={editPassword}
                        ></input>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col xs={12}>
                      <label>Date of expiration</label>
                      <div className='input-box'>
                        <input
                          type='date'
                          placeholder='Set a date...'
                          min={today}
                          onChange={(e) => {
                            setEditExDate(e.target.value);
                          }}
                          value={editExDate}
                        ></input>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col xs={12}>
                      <label>
                        Interactives to showcase (Selected:{' '}
                        {showAllInteractives ? interactiveData.length : selectedInteractive.length})
                      </label>
                      <div className='input-dropdown'>
                        <DropdownCheckbox
                          search={true}
                          className='select-interactive'
                          options={interactiveData.map((interactive) => interactive.interactive_name)}
                          menuText={selectedInteractiveName}
                          //"Select Interactives"
                          onCheck={selectInteractive}
                          onUncheck={unSelectInteractive}
                          selected={selectedInteractive}
                          disable={showAllInteractives}
                        />
                      </div>
                      <div
                        key='all'
                        className={`item ${showAllInteractives ? 'checked' : ''}`}
                        onClick={() => selectAllInteractives()}
                      >
                        <div className='checkbox'>
                          <i className='fas fa-check'></i>
                        </div>
                        <div className='item-name'>Showcase all interactives</div>
                      </div>
                    </Col>
                  </Row>

                  <Row className='mt-5'>
                    <Col xs={12} className='d-flex justify-content-center'>
                      <button
                        onClick={() => {
                          updateUserDetails(editId);
                        }}
                        className='grey-btn'
                      >
                        Update Account Details
                      </button>
                    </Col>
                  </Row>
                  {editFieldEmpty ? (
                    <Row className='mt-1'>
                      <Col className='error text-center' xs={12}>
                        Fields must not be empty
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                </Container>
              ) : (
                <></>
              )}

              {showDisableModal ? (
                <Container className='disable-container'>
                  <Row>
                    <Col className='text-center' xs={12}>
                      <h2 className='header'>Do you really want to disable {disableUsername}?</h2>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col xs={6}>
                      <button
                        onClick={() => {
                          setShowDisableModal(false);
                        }}
                        className='cancel-btn'
                      >
                        Cancel
                      </button>
                    </Col>
                    <Col xs={6}>
                      <button
                        onClick={() => {
                          disableUser();
                        }}
                        className='warning-btn'
                      >
                        Disable Account
                      </button>
                    </Col>
                  </Row>
                </Container>
              ) : (
                <></>
              )}

              {showDeleteModal ? (
                <Container className='delete-container'>
                  <Row>
                    <Col className='text-center' xs={12}>
                      <h2 className='header'>Do you really want to delete {deleteUsername}</h2>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col xs={6}>
                      <button
                        onClick={() => {
                          setShowDeleteModal(false);
                        }}
                        className='cancel-btn'
                      >
                        Cancel
                      </button>
                    </Col>
                    <Col xs={6}>
                      <button
                        onClick={() => {
                          deleteUser();
                        }}
                        className='warning-btn'
                      >
                        Delete Account
                      </button>
                    </Col>
                  </Row>
                </Container>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </Row>

        {/* <Footer /> */}
      </Container>
    </div>
  );
}
