import React, { useState, useEffect, useContext } from 'react';

import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import Thumbnail from './components/thumbnail';
import DropdownCheckbox from './components/dropdown_checkbox';
import GlobalContext from './global_context';


import Cookies from 'universal-cookie';

// import { artwork_logo, animation, paint_anim } from '../static/images';

// import { sortObjectArray } from './utils/utils';

//API urls
import { GALLERY_STORE, API_GET_INTERACTIVE_PREVIEWS } from '../config';
//import Pagination from './components/pagination';
import CenterContainer from './center_container';
//import { use } from 'matter-js';


const text1 = "View and try for yourself how our templates engage with large visitor groups. Explore dynamic options tailored to your needs and see how each template offers a seamless experience. Whether for events or exhibitions, these templates create lasting impressions";
const text2 = "Our trusted clients have successfully used many of our templates in their own spaces. From corporate events to digital trails, these templates deliver results. Read case studies and testimonials to see how they enhanced visitor engagement.";
const text3 = "Find out what kind of assets you would require to maximise the use of our templates. We offer guidance on images, videos, and graphics that complement our templates. Learn how to prepare visual content for a stunning and interactive experience"

export default function Gallery(props) {
  const {
    setShowSignOut,
    clientName
  } = props;

  const cookies = new Cookies();

  const history = useHistory();

  const [input, setInput] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [loading, setLoading] = useState(true);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(999); //use only one page for now

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const [thumbnails, setThumbnails] = useState([]);

  const [tagRows, setTagRows] = useState([]);

  const [interactiveData, setInteractiveData] = useState([]);

  const { interactiveCategories } = useContext(GlobalContext);
  // Do not put the 2 line below above content
  //const currentRecords = thumbnails.slice(indexOfFirstRecord, indexOfLastRecord);

  const nPages = 1;//Math.ceil(content.length / recordsPerPage);

  //type refers to interactive tag
  function addType(index) {
    const newselectedTags = [...selectedTags];
    newselectedTags.push(index);
    setSelectedTags(newselectedTags);
  }

  function removeType(index) {
    let newselectedTags = [...selectedTags];
    newselectedTags = newselectedTags.filter((e) => {
      return e !== index;
    });
    setSelectedTags(newselectedTags);
  }

  //this will only get the incomplete interactive data the user has access to
  async function getInteractivePreviewData() {
    setLoading(true);

    const username = cookies.get('username');
    const pw = cookies.get('password');

    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', pw);

    const res = await fetch(API_GET_INTERACTIVE_PREVIEWS, {
      method: 'POST',
      body: formData,
    });

    const resData = await res.json().catch((error) => console.log('An error occurred ', error));
    if (resData) {
      setInteractiveData(resData);
    }
    //console.log(resData);
  }

  useEffect(()=>{
    //start this async function when the page is loaded
    const fetchData = async () => {
      getInteractivePreviewData();
    };
    fetchData();
  },[]);

  useEffect(() => {
    setShowSignOut(true);

    return () =>{
      setShowSignOut(false);
    };
  }, [setShowSignOut]);

  //update selected tags
  useEffect(() => {
    if (selectedTags.length > 0) {
      const tags = selectedTags.map((v, i) => {
        const value = v;
        const index = i;
        return (
          <Col
            key={`tag-${i}-${interactiveCategories[value]}`}
            xs='auto'
            className={`p-0 mb-2 ${index !== 0 ? 'ml-2' : ''}`}
          >
            <div
              onClick={() => {
                removeType(value);
              }}
              className='tagBox'
            >
              <span>{interactiveCategories[value]}</span>
              <i className='fas fa-times ml-4'></i>
            </div>
          </Col>
        );
      });

      const newTagRow = (
        <Row className='mt-3'>
          <Col xs={{ span: 10, offset: 1 }}>
            <Row>{tags}</Row>
          </Col>
        </Row>
      );
      setTagRows(newTagRow);
    } else {
      setTagRows([]);
    }
  }, [selectedTags]);

  function filterByInput(interArr) {
    let newInterArr = [];
    for (let i = 0; i < interArr.length; i++) {
      if (interArr[i].name.toLowerCase().includes(input.toLowerCase())) {
        newInterArr.push(interArr[i]);
      }
    }
    return newInterArr;
  }

  function filterByTags(interArr) {
    let newInterArr = [];
    let stringTags = [];

    //convert int to string format
    selectedTags.forEach((value) => {
      stringTags.push(interactiveCategories[value]);
    });
    //check if any tag is included
    for (let i = 0; i < interArr.length; i++) {
      const tags = interArr[i].type.split(',');

      if (tags.some((tag) => stringTags.includes(tag))) {
        newInterArr.push(interArr[i]);
      }
    }

    return newInterArr;
  }

  function filterByInput(interArr) {


    let newInterArr = [];
    for (let i = 0; i < interArr.length; i++) {
      if (interArr[i].interactive_name.toLowerCase().includes(input.toLowerCase()) ||
        interArr[i].type.toLowerCase().includes(input.toLowerCase())) {
        newInterArr.push(interArr[i]);
      }
    }

    //merge the two arrays and remove duplicates

    return newInterArr;
  }

  //update interactives after changing tags/sorting method/sorting order/search box input
  useEffect(() => {
    

    // let filteredInteractives=[...allInteractives];
    let filteredInteractives = interactiveData;

    //keyword filter
    if (input !== '') {
      filteredInteractives = filterByInput(filteredInteractives);
    }

    //tags filter
    if (selectedTags.length > 0) {
      filteredInteractives = filterByTags(filteredInteractives);
    }

    const filteredThumbnails = filteredInteractives.map((interactive, index) => {
      const thumbImg = GALLERY_STORE + interactive.thumbnail;
      let thumbGif = GALLERY_STORE + interactive.preview_gif;
      if (interactive.preview_gif == '' || interactive.preview_gif == null || interactive.thumbnail == null) {
        thumbGif = '';
      }

      return (<Thumbnail
        key={`thumbnail-${index}`}
        onClick={() => {
          history.push(`/interactive/${interactive.id}`)
        }}
        image={thumbImg}
        gif={thumbGif}
        //name={interactive.station_name ? interactive.station_name : "Untitled"}
        type={interactive.type}
        style={{marginBottom: "60px"}}
      ></Thumbnail>)
    });

    setThumbnails(filteredThumbnails);
    
    setLoading(false);
  }, [selectedTags, input, interactiveData]);// [selectedTags, sortingMethod, descendingOrder, input, allInteractiveData]

  return (
    <CenterContainer>
      <Container fluid className="gallery-container">
        <Row style={{marginTop:"100px"}}>
          <Col>
            <h1 className="welcome-header">
              <span>Welcome,</span>
              <br/>
              {clientName}
            </h1>

            <p className='theme-label' style={{marginTop:"100px"}}>WHAT TO EXPECT HERE</p>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={4}>
          <div className="grey-top-border">
            <h3 className="sub-header">Try our interactive<br/>templates</h3>
            <p className="info-text">{text1}</p>
          </div>
          </Col>
          <Col xs={4} >
          <div className="grey-top-border">
          <h3 className="sub-header">See how others have<br/>used them</h3>
            <p className="info-text">{text2}</p>
          </div>
          </Col>
          <Col xs={4} >
          <div className="grey-top-border">
          <h3 className="sub-header">View visual<br/>assets required</h3>
          <p className="info-text">{text3}
          </p>
          </div>
          </Col>

          <Col xs={12}>
            <div className='grey-top-border' style={{marginTop:"85px"}}></div>
          </Col>
        </Row>

        <Row style={{marginTop:"70px"}}>
          <Col className='body'>
            <Row>
              <Col className='d-flex justify-content-start' xs={12}>
                <div className='filters-wrapper'>
                  <div className='search-box'>
                    <input
                      onChange={(e) => {
                        setInput(e.target.value);
                      }}
                      placeholder='Search for interactives...'
                    ></input>
                  </div>
                  <DropdownCheckbox
                    className='ml-2'
                    options={interactiveCategories}
                    menuText='Filter by Type'
                    onCheck={addType}
                    onUncheck={removeType}
                    selected={selectedTags}
                  />
                </div>
              </Col> 
            </Row>

            <Row style={{marginTop:"80px"}}>
              <Col>
                <h2 className='main-header text-center'>Interactives</h2>
                <p className='text-center tagline' style={{marginTop:"32px"}}>Carefully curated and selected to fit your objectives and requirements</p>
              </Col>
            </Row>
            
            {tagRows}
            
            {
              !loading ? (
              <Row style={{marginTop:"80px"}}>
                {thumbnails}
              </Row>
            ) : (
                <Row className='mt-5 scroll-section'>
                    <Col className='d-flex justify-content-center'>
                      <Spinner
                        style={{ width: '60px', height: '60px' }}
                        animation='grow'
                        variant='secondary'
                      ></Spinner>
                    </Col>
                  </Row>
              )
            }

          </Col>
        </Row>
        {/* <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} /> */}

        {/* <Footer /> */}
      </Container>
    </CenterContainer>
      
    //</div>
  );
}


// function Thumbnail(props) {
//   const { image, name, type} = props;
//   const [hovering, setHovering] = useState(false);
//   return (
//     <Col xs={3} className="pl-2 pr-2 d-flex">
//       <span className='sub-header'>{name}</span>
//       <img src={image} alt={name}></img>
//         {hovering&&gif?
//         <img className={` animate__animated animate__fadeIn animate__faster`} src={gif} alt="gif"></img>
//         :
//         <></>
//         }
//       <span className='small-grey-text'>{type}</span>
//     </Col>
//   );
// }