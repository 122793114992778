import React, { useState, useEffect } from 'react';

//import {Link,useHistory} from "react-router-dom"
import Cookies from 'universal-cookie';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';


//import Footer from './components/Footer';
import DropdownCheckbox from './components/dropdown_checkbox';

//import {isNumeric} from "./utils/utils"

import { magnifier, black_cross } from '../static/images';

//API urls
import {
  API_ADMIN_GET_ADMINS,
  API_ADMIN_CREATE_USER,
  API_ADMIN_DISABLE_USER,
  API_ADMIN_DELETE_USER,
  API_ADMIN_CREATE_ADMIN,
  API_ADMIN_UPDATE_ADMIN,
} from '../config';

import Pagination from './components/pagination';

//get today's date
const d = new Date();
export default function Admin(props) {
  const { adminAccount, adminPassword, userId } = props;

  const cookies = new Cookies();

  const [today, setToday] = useState(
    `${d.getFullYear()}-${d.getMonth() >= 9 ? '' : '0'}${d.getMonth() + 1}-${
      d.getDate() >= 10 ? '' : '0'
    }${d.getDate()}`
  );

  const [input, setInput] = useState('');

  const [allUsers, setAllUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);

  const [tableRows, setTableRows] = useState([]);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);

  //for create account modal
  const [createUsername, setCreateUsername] = useState('');
  const [createPassword, setCreatePassword] = useState('');
  const [createExDate, setCreateExDate] = useState('');
  const [createRole, setCreateRole] = useState('');
  const [createFieldEmpty, setCreateFieldEmpty] = useState(false);

  const [editId, setEditId] = useState('');
  const [editUsername, setEditUsername] = useState('');
  const [editPassword, setEditPassword] = useState('');
  const [editExDate, setEditExDate] = useState('');
  const [editStatus, setEditStatus] = useState(false);
  const [editFieldEmpty, setEditFieldEmpty] = useState(false);

  const [disableId, setDisableId] = useState('');
  const [disableUsername, setDisableUsername] = useState('');

  const [deleteId, setDeleteId] = useState('');
  const [deleteUsername, setDeleteUsername] = useState('');
  const [deleteRole, setDeleteRole] = useState('');

  const [processing, setProcessing] = useState(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const currentRecords = tableRows.slice(indexOfFirstRecord, indexOfLastRecord);

  const nPages = Math.ceil(tableRows.length / recordsPerPage);

  //for selecting interactives
  const [selectedInteractive, setSelectedInteractive] = useState([]);
  const [selectedInteractiveName, setSelectedInteractiveName] = useState([]);
  const [showAllInteractives, setShowAllInteractives] = useState(false);
  const allInteractives = [];

  // interactives.map((interactive, index) => {
  //   let withIndex = interactive;
  //   withIndex.index = index + 1;

  //   return withIndex;
  // });

  let displayInteractiveNames = allInteractives.map((interactive) => interactive.name);

  //for account rights
  const [accountRights, setAccountRights] = useState([]);
  const [accessZero, setAccessZero] = useState(false);
  const [accessOne, setAccessOne] = useState(false);
  const [accessTwo, setAccessTwo] = useState(false);
  const accessTags = ['Add New Interactive', 'Client Management', 'Admin Management', 'All Access Granted'];

  //when the page is loaded, automatically run if the two states are set
  useEffect(() => {
    if (adminAccount && adminPassword) {
      fetchUsersData();
    }
  }, []);

  //update selected interactives to display to user
  useEffect(() => {
    if (selectedInteractive.length === 0) {
      setSelectedInteractiveName(['Select Interactives']);
    } else {
      let interactiveName = [];
      for (let i = 0; i < selectedInteractive.length; i++) {
        if (selectedInteractive.length === 1) {
          interactiveName.push(displayInteractiveNames[selectedInteractive[i]]);
        } else {
          interactiveName.push(displayInteractiveNames[selectedInteractive[i]] + ', ');
        }
      }
      setSelectedInteractiveName(interactiveName);
    }
  }, [selectedInteractive]);

  function generateUsername() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    let string = '_' + Math.random().toString(36).substr(2, 9);

    return string;
  }

  function generatePassword() {
    let length = 11,
      charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
      string = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      string += charset.charAt(Math.floor(Math.random() * n));
    }

    return string;
  }

  //select interactives
  function selectInteractive(index) {
    const newSelected = [...selectedInteractive];
    newSelected.push(index);
    setSelectedInteractive(newSelected);
  }

  function accountAccess(access) {
    const newRights = [...accountRights];
    if (newRights.includes(access)) {
      newRights.splice(newRights.indexOf(access), 1);
    } else {
      newRights.push(access);
    }
    newRights.sort();

    setAccountRights(newRights);

    if (access === 0) {
      setAccessZero(!accessZero);
    } else if (access === 1) {
      setAccessOne(!accessOne);
    } else if (access === 2) {
      setAccessTwo(!accessTwo);
    }
  }

  function accessDisplay(access) {
    let accessCol = [];

    const accessRights = access.split(',');

    if (accessRights.length === 3) {
      return accessTags[3];
    } else {
      //new
      for (let j = 0; j < accessRights.length; j++) {
        if (j === accessRights.length - 1) {
          accessCol.push(accessTags[accessRights[j]]);
        } else {
          accessCol.push(accessTags[accessRights[j]] + ', ');
        }
      }

      return accessCol;
    }
  }

  function appendTimeToDate(date) {
    const d = `${date} 23:59:59`;

    return d;
  }

  function currentTimeStamp() {
    const d = new Date();
    const td = `${d.getFullYear()}-${d.getMonth() >= 9 ? '' : '0'}${d.getMonth() + 1}-${
      d.getDate() >= 10 ? '' : '0'
    }${d.getDate()}`;
    const time = d.toTimeString().split(' ')[0];

    const currentTimeStamp = td + ' ' + time;
    return currentTimeStamp;
  }

  function openCreateModal() {
    setCreateUsername('');
    setCreatePassword('');
    setCreateExDate('');
    setCreateFieldEmpty(false);
    setSelectedInteractive([]);
    setShowAllInteractives(false);
    setShowCreateModal(true);
    //set to false on open
    setAccessZero(false);
    setAccessOne(false);
    setAccessTwo(false);
    setAccountRights([]);
  }

  function openEditModal(index) {
    //set to false on open
    setAccessZero(false);
    setAccessOne(false);
    setAccessTwo(false);
    setEditStatus(false);

    const user = displayedUsers[index];
    setEditId(user.id);
    setEditUsername(user.account);
    setEditPassword(user.password);
    //disable or enable account
    if (user.expiration_date != null) {
      const now = Date.now();
      let d = Date.parse(user.expiration_date);
      let expired = now > d;
      setEditStatus(expired);
      setEditExDate(user.expiration_date.split(' ')[0]);
    }
    //show account rights
    const accessRights = user.access.split(',').map(Number);
    for (let i = 0; i < accessRights.length; i++) {
      accountAccess(accessRights[i]);
    }
    setAccountRights(accessRights);
    setEditFieldEmpty(false);
    setShowEditModal(true);
  }

  function openDisableModal(index) {
    if (userId !== displayedUsers[index].id) {
      setDisableId(displayedUsers[index].id);
      setDisableUsername(displayedUsers[index].username);
      setShowDisableModal(true);
    }
  }

  function openDeleteModal(index) {
    if (userId !== displayedUsers[index].id) {
      setDeleteRole(displayedUsers[index].role);
      setDeleteId(displayedUsers[index].id);
      setDeleteUsername(displayedUsers[index].username);
      setShowDeleteModal(true);
    }
  }

  function updateAdminPassword(accountInput, passwordInput) {
    cookies.remove('adminAccount');
    cookies.remove('adminPassword');
    cookies.set('adminAccount', accountInput, { path: '/', maxAge: 3600 });
    cookies.set('adminPassword', passwordInput, { path: '/', maxAge: 3600 });
    window.location.reload();
  }

  async function fetchUsersData() {
    const data = {
      adminAccount: adminAccount,
      adminPassword: adminPassword,
    };
    const res = await fetch(API_ADMIN_GET_ADMINS, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const resData = await res.json();

    if (resData) {
      setAllUsers(resData);
    }
  }

  async function createNewAdmin() {
    if (createUsername && createPassword && !processing) {
      setProcessing(true);

      if (accountRights.length !== 0) {
        var userRole = 2;
        if (accountRights.length === 3) {
          userRole = 1;
        }

        const data = {
          adminAccount: adminAccount,
          adminPassword: adminPassword,
          username: createUsername,
          userPassword: createPassword,
          expirationDate: '',
          interactives: selectedInteractive,
          role: userRole,
        };

        const res = await fetch(API_ADMIN_CREATE_USER, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        const resData = await res.json();

        if (resData === 'Success') {
          const data = {
            adminAccount: adminAccount,
            adminPassword: adminPassword,
            username: createUsername,
            userPassword: createPassword,
            access: accountRights,
          };

          const res2 = await fetch(API_ADMIN_CREATE_ADMIN, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });

          const resData2 = await res2.json();

          if (resData2 === 'Success') {
            setShowCreateModal(false);
            fetchUsersData();
            window.location.reload();
          }
        }
      } else {
        setCreateFieldEmpty(true);
      }
    } else {
      setCreateFieldEmpty(true);
    }
    setProcessing(false);
  }

  async function updateAdminDetails() {
    if (editUsername && editPassword && !processing) {
      setProcessing(true);

      if (accountRights.length !== 0) {
        var userRole = 2;
        if (accountRights.length === 3) {
          userRole = 1;
        }

        var editExpirationDate = editExDate;
        if (!editStatus) {
          editExpirationDate = '';
        }

        const data = {
          adminAccount: adminAccount,
          adminPassword: adminPassword,
          id: editId,
          username: editUsername,
          userPassword: editPassword,
          expirationDate: editExpirationDate,
          role: userRole,
          access: accountRights,
        };

        const res = await fetch(API_ADMIN_UPDATE_ADMIN, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        const resData = await res.json();

        if (resData === 'Success') {
          setShowEditModal(false);
          fetchUsersData();

          if (editId === userId) {
            updateAdminPassword(editUsername, editPassword);
            window.location.reload();
          } else {
            window.location.reload();
          }
        } else {
          console.log(resData);
        }
      } else {
        setEditFieldEmpty(true);
      }
    } else {
      setEditFieldEmpty(true);
    }
    setProcessing(false);
  }

  async function disableUser() {
    if (disableId && !processing) {
      setProcessing(true);

      const data = {
        adminAccount: adminAccount,
        adminPassword: adminPassword,
        id: disableId,
      };

      const res = await fetch(API_ADMIN_DISABLE_USER, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const resData = await res.json();
      if (resData === 'Success') {
        setShowDisableModal(false);
        setDisableId('');
        setDisableUsername('');
        fetchUsersData();
        window.location.reload();
      }
    }

    setProcessing(false);
  }

  async function deleteUser() {
    if (deleteId && !processing) {
      setProcessing(true);

      const data = {
        adminAccount: adminAccount,
        adminPassword: adminPassword,
        id: deleteId,
        role: deleteRole,
      };

      const res = await fetch(API_ADMIN_DELETE_USER, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const resData = await res.json();
      if (resData === 'Success') {
        setShowDeleteModal(false);
        setDeleteId('');
        setDeleteUsername('');
        setDeleteRole('');
        fetchUsersData();
      }
    }
    setProcessing(false);
  }

  function filterUsersByInput(usersArr) {
    let newArr = [];

    for (let i = 0; i < usersArr.length; i++) {
      if (usersArr[i].id === input) {
        newArr.push(usersArr[i]);
      } else if (usersArr[i].username.toLowerCase().includes(input.toLowerCase())) {
        newArr.push(usersArr[i]);
      }
    }

    return newArr;
  }

  useEffect(() => {
    if (allUsers.length > 0) {
      let filteredUsers = [...allUsers];

      if (input !== '') {
        filteredUsers = filterUsersByInput(filteredUsers);
      }

      setDisplayedUsers(filteredUsers);
    }
  }, [input, allUsers]);

  useEffect(() => {
    const now = Date.now();
    var d = '';

    const rows = displayedUsers.map((value, index) => {
      var expired = false;
      if (value.expiration_date != null) {
        d = Date.parse(value.expiration_date);
        if (now > d) {
          expired = true;
        }
      }

      let ownAccount = false;

      if (value.id === userId) {
        ownAccount = true;
      }

      return (
        <tr key={`row-id-${value.id}`}>
          <td className='text'>{index + 1}</td>
          <td className='text'>{value.id}</td>
          <td className='text'>{value.account}</td>
          <td className='text'>{value.password}</td>
          <td className='text'>{accessDisplay(value.access)}</td>
          <td className={`text ${expired ? 'expired' : ''}`}>{expired ? 'Disabled' : 'Available'}</td>
          <td
            onClick={() => {
              openEditModal(index);
            }}
            className='icon'
          >
            <i className='fas fa-pen'></i>
          </td>
          <td
            onClick={() => {
              openDisableModal(index);
            }}
            className='icon'
          >
            <i className={`fas fa-ban ${ownAccount ? 'disabled' : ''}`}></i>
          </td>
          <td
            onClick={() => {
              openDeleteModal(index);
            }}
            className='icon'
          >
            <i className={`fas fa-trash-alt ${ownAccount ? 'disabled' : ''}`}></i>
          </td>
        </tr>
      );
    });

    setTableRows(rows);
  }, [displayedUsers]);

  return (
    <div className='admin-container'>
      <Container fluid>
        <Row className='table-section'>
          <Col xs={12} className='table-container'>
            <Row>
              <Col xs='auto'>
                <h2 className='header'>Admin Accounts</h2>
              </Col>
              <Col className='d-flex justify-content-end'>
                <div className='search-box'>
                  <input
                    onChange={(e) => {
                      setInput(e.target.value);
                    }}
                    placeholder='Search for accounts...'
                  ></input>
                  <img alt='magnifier' src={magnifier}></img>
                </div>

                <button
                  onClick={() => {
                    openCreateModal();
                  }}
                  className='grey-btn ml-3'
                >
                  Create New Admin
                </button>
              </Col>
            </Row>

            <Row className='mt-5'>
              <Col xs={12}>
                <Table responsive className='account-table' striped borderless>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Account ID.</th>
                      <th>Username</th>
                      <th>Password</th>
                      <th>Account has access to...</th>
                      <th>Account Status</th>
                      <th className='icon-col'>Edit</th>
                      <th className='icon-col'>Disable</th>
                      <th className='icon-col'>Delete</th>
                    </tr>
                  </thead>
                  <tbody>{currentRecords}</tbody>
                </Table>
                <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
              </Col>
            </Row>
          </Col>

          {showCreateModal || showEditModal || showDisableModal || showDeleteModal || showResultModal ? (
            <div className='modal-wrapper'>
              {showCreateModal ? (
                <Container className='create-container'>
                  <Row className='position-relative'>
                    <div
                      onClick={() => {
                        setShowCreateModal(false);
                      }}
                      className='close-cross-btn'
                    >
                      <img alt='close' src={black_cross}></img>
                    </div>
                    <Col xs={12}>
                      <h2 className='header'>Create New Admin Account</h2>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col xs={12}>
                      <label>Username</label>
                      <div className='input-box'>
                        <input
                          value={createUsername}
                          onChange={(e) => {
                            setCreateUsername(e.target.value);
                          }}
                          placeholder='Set a username...'
                        ></input>
                        <i
                          onClick={() => {
                            setCreateUsername(generateUsername());
                          }}
                          className='shuffle-icon fas fa-random'
                        ></i>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col xs={12}>
                      <label>Password</label>
                      <div className='input-box'>
                        <input
                          placeholder='Set a password...'
                          value={createPassword}
                          onChange={(e) => {
                            setCreatePassword(e.target.value);
                          }}
                        ></input>
                        <i
                          onClick={() => {
                            setCreatePassword(generatePassword());
                          }}
                          className='shuffle-icon fas fa-random'
                        ></i>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-3 flex-column'>
                    <Col xs={12}>
                      <label>Account Access</label>
                      <Row>
                        <Col xs={4}>
                          <div
                            key='all'
                            className={`item ${accessZero ? 'checked' : ''}`}
                            onClick={() => accountAccess(0)}
                          >
                            <div className='checkbox'>
                              <i className='fas fa-check'></i>
                            </div>
                            <div className='item-name'>Add New Interactive</div>
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div
                            key='all'
                            className={`item ${accessOne ? 'checked' : ''}`}
                            onClick={() => accountAccess(1)}
                          >
                            <div className='checkbox'>
                              <i className='fas fa-check'></i>
                            </div>
                            <div className='item-name'>Client Management</div>
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div
                            key='all'
                            className={`item ${accessTwo ? 'checked' : ''}`}
                            onClick={() => accountAccess(2)}
                          >
                            <div className='checkbox'>
                              <i className='fas fa-check'></i>
                            </div>
                            <div className='item-name'>Admin Management</div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className='mt-5'>
                    <Col xs={12} className='d-flex justify-content-center'>
                      <button
                        onClick={() => {
                          createNewAdmin();
                        }}
                        className='grey-btn'
                      >
                        Create New Account
                      </button>
                    </Col>
                  </Row>
                  {createFieldEmpty ? (
                    <Row className='mt-1'>
                      <Col className='error text-center' xs={12}>
                        Fields must not be empty
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                </Container>
              ) : (
                <></>
              )}

              {showEditModal ? (
                <Container className='edit-container'>
                  <Row className='position-relative'>
                    <div
                      onClick={() => {
                        setShowEditModal(false);
                      }}
                      className='close-cross-btn'
                    >
                      <img alt='close' src={black_cross}></img>
                    </div>
                    <Col xs={12}>
                      <h2 className='header'>Edit</h2>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col xs={12}>
                      <label>Username</label>
                      <div className='input-box'>
                        <input
                          placeholder='Set a username...'
                          onChange={(e) => {
                            setEditUsername(e.target.value);
                          }}
                          value={editUsername}
                        ></input>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col xs={12}>
                      <label>Password</label>
                      <div className='input-box'>
                        <input
                          placeholder='Set a password...'
                          onChange={(e) => {
                            setEditPassword(e.target.value);
                          }}
                          value={editPassword}
                        ></input>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col xs={12}>
                      <label>Account Status: {editStatus ? 'Disabled' : 'Available'}</label>
                      <div
                        style={{ display: editStatus ? '' : 'none' }}
                        key='all'
                        className={`item ${editStatus ? '' : 'checked'}`}
                        onClick={() => setEditStatus(!editStatus)}
                      >
                        <div className='checkbox'>
                          <i className='fas fa-check'></i>
                        </div>
                        <div className='item-name'>Enable Account</div>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col xs={12}>
                      <label>Account Access</label>
                      <Row>
                        <Col xs={4}>
                          <div
                            key='all'
                            className={`item ${accessZero ? 'checked' : ''}`}
                            onClick={() => accountAccess(0)}
                          >
                            <div className='checkbox'>
                              <i className='fas fa-check'></i>
                            </div>
                            <div className='item-name'>Add New Interactive</div>
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div
                            key='all'
                            className={`item ${accessOne ? 'checked' : ''}`}
                            onClick={() => accountAccess(1)}
                          >
                            <div className='checkbox'>
                              <i className='fas fa-check'></i>
                            </div>
                            <div className='item-name'>Client Management</div>
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div
                            key='all'
                            className={`item ${accessTwo ? 'checked' : ''}`}
                            onClick={() => accountAccess(2)}
                          >
                            <div className='checkbox'>
                              <i className='fas fa-check'></i>
                            </div>
                            <div className='item-name'>Admin Management</div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className='mt-5'>
                    <Col xs={12} className='d-flex justify-content-center'>
                      <button
                        onClick={() => {
                          updateAdminDetails(editId);
                        }}
                        className='grey-btn'
                      >
                        Update Account Details
                      </button>
                    </Col>
                  </Row>
                  {editFieldEmpty ? (
                    <Row className='mt-1'>
                      <Col className='error text-center' xs={12}>
                        Fields must not be empty
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                </Container>
              ) : (
                <></>
              )}

              {showDisableModal ? (
                <Container className='disable-container'>
                  <Row>
                    <Col className='text-center' xs={12}>
                      <h2 className='header'>Do you really want to disable {disableUsername}?</h2>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col xs={6}>
                      <button
                        onClick={() => {
                          setShowDisableModal(false);
                        }}
                        className='cancel-btn'
                      >
                        Cancel
                      </button>
                    </Col>
                    <Col xs={6}>
                      <button
                        onClick={() => {
                          disableUser();
                        }}
                        className='warning-btn'
                      >
                        Disable Account
                      </button>
                    </Col>
                  </Row>
                </Container>
              ) : (
                <></>
              )}

              {showDeleteModal ? (
                <Container className='delete-container'>
                  <Row>
                    <Col className='text-center' xs={12}>
                      <h2 className='header'>Do you really want to delete {deleteUsername}</h2>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col xs={6}>
                      <button
                        onClick={() => {
                          setShowDeleteModal(false);
                        }}
                        className='cancel-btn'
                      >
                        Cancel
                      </button>
                    </Col>
                    <Col xs={6}>
                      <button
                        onClick={() => {
                          deleteUser();
                        }}
                        className='warning-btn'
                      >
                        Delete Account
                      </button>
                    </Col>
                  </Row>
                </Container>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </Row>

        {/* <Footer /> */}
      </Container>
    </div>
  );
}
