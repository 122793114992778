import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, useHistory } from 'react-router-dom';
import MenuIcon from './menu_icon';
import SideBar from './menu_sidebar';
import Cookies from 'universal-cookie';

import { hol_logo_colored } from '../../static/images';
//import  Container from 'react-bootstrap/Container';
export default function Topbar(props) {
  const { role, setLoggedIn, setAdminLoggedIn, showSignOut, show} = props;
  const history = useHistory();
  const cookies = new Cookies();
  const cookiesLoggedIn = cookies.get('userLoggedIn') === 'true';
  //const adminLoggedIn = cookies.get('userLoggedIn2') === 'true';

  //mobile menu
  const [open, setOpen] = useState(false);

  function signOut() {
    cookies.remove('userLoggedIn');
    cookies.remove('userLoggedIn2');
    //cookies.remove('adminLoggedIn');
    cookies.remove('adminAccount');
    cookies.remove('adminPassword');
    cookies.remove('userame');
    cookies.remove('password');
    cookies.remove('role');
    cookies.remove('interactives');
    cookies.remove('adminAccess');
    cookies.remove('id');
    cookies.remove('a1');
    cookies.remove('a2');
    cookies.remove('a3');
    setLoggedIn(false);
    setAdminLoggedIn(false);
    //for error page
    window.location.href = '/';
  }

  if(show){
    return (<>
      {cookiesLoggedIn ? (
      <SideBar
        open={open}
        setOpen={setOpen}
      />) : <></>}

      <Row style={{height: '90px'}} className="align-items-center topbar mt-3 mb-3">
        <Col>
          <img className="logo" src={hol_logo_colored} onClick={()=>{history.push("/home")}} alt="hol logo"></img>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
            <button
              onClick={() => {
                signOut();
              }}
              className='signout-btn mr-5'
              style={{ display: cookiesLoggedIn && showSignOut ? '' : 'none' }}
            >
              Sign Out
            </button>
            {
              cookiesLoggedIn ? <i onClick={()=>{setOpen(!open)}} className="sidebar-toggle fas fa-2x fa-bars hamburger-icon"></i> : <></>
            }
            
        </Col>
      </Row>
    </>);
  }

  return <></>;

  // return (
  //   <Row>
  //     <Col className='topbar'>
  //       <Row>
  //         <Col xs={1} className='d-flex justify-content-end align-items-center'>
  //           <MenuIcon open={open} setOpen={setOpen} role={role} />
  //           <SideBar
  //             open={open}
  //             setOpen={setOpen}
  //             adminOne={adminOne}
  //             adminTwo={adminTwo}
  //             adminThree={adminThree}
  //           />
  //         </Col>
  //         <Col
  //           className='logo-wrapper d-flex justify-content-center align-items-center pad-42'
  //           xs={{ span: 4, offset: 3 }}
  //         >
  //           <Link to={{ pathname: '/' }}>
  //             <img alt='logo' src={hol_logo_colored}></img>
  //           </Link>
  //         </Col>
  //         <Col xs={4} className='d-flex justify-content-end align-items-center pad-42'>
  //           <Link to={{ pathname: '/new-upload' }}>
  //             <div style={{ display: cookiesLoggedIn ? '' : 'none' }}>
  //               <button
  //                 className='add-interactive-btn'
  //                 style={{ display: role === 0 ? 'none' : adminOne ? '' : 'none' }}
  //               >
  //                 <i className='bi bi-plus-lg'></i> Add New Interactive
  //               </button>
  //             </div>
  //           </Link>
  //           <button
  //             onClick={() => {
  //               signOut();
  //             }}
  //             className='signout-btn'
  //             style={{ display: cookiesLoggedIn ? '' : 'none' }}
  //           >
  //             Sign Out
  //           </button>
  //         </Col>
  //       </Row>
  //     </Col>
  //   </Row>
  // );
}
