import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom';

import Cookies from 'universal-cookie';

import './App.scss';
import 'rc-slider/assets/index.css';

//import AdminLogin from './app/admin_login';
import Topbar from './app/components/Topbar';
import Admin from './app/admin';
import Client from './app/client';

import Login from './app/login';
import Gallery from './app/gallery';

import InteractiveForm from './app/gimme/interactive_form';
import Gimme from './app/gimme';
//import UploadInteractive from './app/upload';
import AssetsPage from './app/assets_page';

import NotFound from './app/error/404';

//API urls
import CenterContainer from './app/center_container';
import Container from 'react-bootstrap/Container';

import { GlobalContextProvider } from './app/global_context';
import { API_GET_INTERACTIVE_CATEGORIES } from './config';

const restricted_access_png = require('./static/images/mobile/restricted_access.png');

function App() {
  const cookies = new Cookies();

  const cookiesLoggedIn = cookies.get('userLoggedIn') === 'true'; //check cookie if the user is logged in
  const cookiesAdminLoggedIn = cookies.get('userLoggedIn2');
  const cookiesRole = Number(cookies.get('role'));
  const cookiesId = cookies.get('id');

  const [loggedIn, setLoggedIn] = useState(cookiesLoggedIn); //state for storing loggin status, once logged in we no long verify account or password to view the interactives

  //these states are for the admin page, every operation in admin page require sending account and password to server to verify, hence we need state to store them
  const [adminLoggedIn, setAdminLoggedIn] = useState(cookiesAdminLoggedIn);
  const [adminAccount, setAdminAccount] = useState(cookies.get('adminAccount') || '');
  const [adminPassword, setAdminPassword] = useState(cookies.get('adminPassword') || '');
  const [adminAccess, setAdminAccess] = useState(cookies.get('adminAccess') || '');
  const [userRole, setUserRole] = useState(cookiesRole);
  const [userId, setUserId] = useState(cookiesId);

  const [showTopbar, setShowTopbar] = useState(true);
  const [showSignOut, setShowSignOut] = useState(false);

  const [adminOne, setAdminOne] = useState(false);
  const [adminTwo, setAdminTwo] = useState(false);
  const [adminThree, setAdminThree] = useState(false);

  const [clientName, setClientName] = useState('HOL');

  const [interactiveCategories, setInteractiveCategories] = useState([]);

  const history = useHistory();

  //execute async function to fetch categories
  async function FetchCategories() {
    try {
      const response = await fetch(API_GET_INTERACTIVE_CATEGORIES,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! ${response.status}`);
      }
  
      const data = await response.json();
      //console.log('Fetched categories:', data);
      setInteractiveCategories(data);
    } catch (error) {
      console.error('Failed to fetch categories:', error);
      // Optionally, set an error state here
    }
  }


  useEffect(()=>{
    async function fetch(){
      await FetchCategories();
    }

    fetch();
  },[])

  useEffect(() => {
    //when open a new page, scroll to top
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });

    // Cleanup the listener on component unmount
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    if (loggedIn) {
      // if logged in, remove admin cookies first
      for(let i = 1; i <= 3; i++) {
        cookies.remove(`a${i}`, { path: '/' });
      }

      if (adminAccess !== '') {
        // check admin rights
        const accessRights = adminAccess.split(',').map(Number);
        for (let i = 0; i < accessRights.length; i++) {
          cookies.set(`a${i + 1}`, true, { path: '/' });
        }
      }

      // set admin rights
      setAdminOne(cookies.get('a1'));
      setAdminTwo(cookies.get('a2'));
      setAdminThree(cookies.get('a3'));
    }
  }, [loggedIn, adminAccess]);



  if (isMobile) {
    return (
      <div className="mobile-warning">
        <img src={restricted_access_png} alt='Please access the gallery through a PC.'></img>
      </div>
    );
  }

  return (

        <div className='App'>
          <GlobalContextProvider 
          interactiveCategories={interactiveCategories} 
          refreshInteractiveCategories={FetchCategories}>
            <CenterContainer >
              <Container fluid className='side-padding'>
                <Topbar
                  show={showTopbar}
                  role={userRole}
                  setLoggedIn={setLoggedIn}
                  setAdminLoggedIn={setAdminLoggedIn}
                  showSignOut={showSignOut}
                />
              </Container>

            </CenterContainer>
            <Switch>
              <Route exact path='/404'>
                <NotFound
                ></NotFound>
              </Route>

              {/* if not logged in then redirect to login page, same logic for other paths */}
              <Route exact path='/'>
                {loggedIn ? (
                  <Redirect to='/home' />
                ) : (
                  <Login
                    setLoggedIn={setLoggedIn}
                    setAdminLoggedIn={setAdminLoggedIn}
                    setAdminAccount={setAdminAccount}
                    setAdminPassword={setAdminPassword}
                    setUserRole={setUserRole}
                    setUserId={setUserId}
                    setAdminAccess={setAdminAccess}
                    setClientName={setClientName}
                  />
                )}
              </Route>

              <Route exact path='/home'>
                {loggedIn ? (
                  <Gallery
                    setShowSignOut={setShowSignOut}
                    clientName={clientName}
                  />
                ) : (
                  <Redirect to='/' />
                )}
              </Route>

              
              <Route exact path='/interactive/:id'>
                {loggedIn ? (
                  <Gimme 
                  canEdit={adminOne} 
                  />
                ) : (
                  <Redirect to='/' />
                )}
              </Route>

              <Route exact path='/interactive/:id/assets'>
                {loggedIn ? (
                  <AssetsPage 
                  canEdit={adminOne} 
                  />
                ) : (
                  <Redirect to='/' />
                )}
              </Route>


              <Route exact path='/new-upload'>
                {loggedIn && adminOne ? (
                  <InteractiveForm 
                  />
                ) : (
                  <Redirect to='/' />
                )}
              </Route>

              <Route exact path='/client_accounts'>
                {loggedIn && adminTwo ? (
                  <Client
                    adminAccount={adminAccount}
                    adminPassword={adminPassword}
                  />
                ) : (
                  <Redirect to='/' />
                )}
              </Route>

              <Route exact path='/admin_accounts'>
                {loggedIn && adminThree ? (
                  <Admin
                    adminAccount={adminAccount}
                    adminPassword={adminPassword}
                    userId={userId}

                  />
                ) : (
                  <Redirect to='/' />
                )}
              </Route>

              <Route path=''>
                <Redirect to='/404'></Redirect>
              </Route>
            </Switch>
          </GlobalContextProvider>
        </div>

  );
}

export default App;
