import React, {useState} from 'react';
import { Row, Col, Form,Button } from 'react-bootstrap';
import ConfirmModal from '../components/confirm_modal';
import { CarouselItemTypes } from './interactive_data';
import { isValidYouTubeEmbed, createLocalUrl } from '../utils/utils';
import UploadImageContainer from '../components/upload_image_container';
import { GALLERY_STORE, API_CREATE_CAROUSEL_ITEM, API_DELETE_CAROUSEL_ITEM } from '../../config';
import Cookies from 'universal-cookie';
// const dummyItems = [
//     {url_type: 'image', url: 'hol_showcase/gallery_store/404.png'},
//     {url_type: 'Youtube', url: 'some links'},
//     {url_type: 'iframe', url: 'some links'},
// ]
//ownerType: gimme/client
export default function ModifyCarousel({ownerType, ownerId, carouselItems = [], onCarouselUpdate}){
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [currentOperation, setCurrentOperation] = useState();
    const [newItem, setNewItem] = useState({type: CarouselItemTypes[0], content: null});

    async function deleteItem(index){
        setProcessing(true);
        setErrMsg('');

        try {
            const cookies = new Cookies();
            const formData = new FormData();
            formData.append('adminAccount', cookies.get('adminAccount'));
            formData.append('adminPassword', cookies.get('adminPassword'));
            formData.append('owner_type', ownerType);
            formData.append('owner_id', ownerId);
            formData.append('carousel_item_id', carouselItems[index].id);

            const response = await fetch(API_DELETE_CAROUSEL_ITEM, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            const responseData = await response.json();
            console.log('Item deleted successfully:', responseData);
            setShowConfirmModal(false);
            if (onCarouselUpdate) {
                onCarouselUpdate();
            }
        } catch (error) {
            console.error('Error deleting item:', error);
            setErrMsg(error.message);
        } finally {
            setProcessing(false);
        }
    }

    async function addItem(){
        //add item to carouselItems
        setProcessing(true);
        setErrMsg('');
        try{
            if(!newItem)
                throw new Error('Please provide a valid content for the new item');

            if(newItem.type === 'image' && (!newItem.content || !newItem.content.file))
                throw new Error('Please provide a valid image file for the new item');

            const cookies = new Cookies();
            const formData = new FormData();
            formData.append('adminAccount', cookies.get('adminAccount'));
            formData.append('adminPassword', cookies.get('adminPassword'));
            formData.append('owner_type', ownerType);
            formData.append('owner_id', ownerId);
            formData.append('url_type', newItem.type);

            if(newItem.type === 'image'){
                formData.append('image', newItem.content.file);
            }
            else{
                formData.append('url', newItem.content);
            }

            const response = await fetch(API_CREATE_CAROUSEL_ITEM, {
                method: 'POST',
                body: formData
            });
    
            if (!response.ok) {
                // Handle HTTP errors
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }
    
            const responseData = await response.json();
            //console.log('Success:', responseData);
            setShowConfirmModal(false);
    
            if(onCarouselUpdate){
                onCarouselUpdate();
            }
            setNewItem({type: CarouselItemTypes[0], content: null});
        } catch(err){
            setErrMsg(err.message);
        } finally{
            setProcessing(false);
        }
    }

    const handleItemChange = (field, value) => {
        const updatedItem = { ...newItem, [field]: value };
        if(field === 'type'){
            updatedItem.content = null;
        }
        setNewItem(updatedItem);
      };

      //console.log('carouselItems', carouselItems);

    return (<>

            <Row>
                {
                carouselItems.map((item, index) => 
                    <Col key={`carousel-item-${index}`} xs={12} md={item.url_type==='iframe'?12:6} >
                        <Form.Group controlId={`carouselItem-${index}`}>
                            <Form.Label>{item.url_type}</Form.Label>
                                <div className='d-flex flex-column'>
                                {item.url_type === 'image' ?
                                <img src={GALLERY_STORE+item.url} 
                                alt='carousel item' 
                                style={{width:"150px", height:"150px", objectPosition:"center", objectFit:"contain"}} 
                                /> 
                                : 
                                <Form.Control
                                type="text"
                                value={item.url}
                                placeholder=""
                                readOnly
                                />}

                                <Button style={{width:"150px"}} className='mt-2' variant="danger" 
                                onClick={()=>{
                                    setCurrentOperation(()=>()=>deleteItem(index));
                                    setShowConfirmModal(true);
                                }}>Delete</Button>
                            </div>
                        </Form.Group>
                    </Col>
                )
                }
            </Row>
            <Row>
                <Col xs={12}>
                <h5>Add New Carousel Item</h5>
                </Col>
                <Col>
                <Form.Group controlId={`carouselType`}>
                    <Form.Label>Type</Form.Label>
                    <Form.Control
                        as="select"
                        value={newItem.type}
                        onChange={(e) => {handleItemChange('type', e.target.value);}}
                    >
                        {CarouselItemTypes.map((option, index) => {
                            return <option key={index} value={option}>{option}</option>
                        })}

                    </Form.Control>
                    </Form.Group>

                    {newItem.type === 'image' && (
                    <UploadImageContainer 
                    label="Image" 
                    accept='image/*' 
                    imgData={newItem.content && newItem.content.url} 
                    id={"carouselImage"}
                    onChangeImg={(e) => {
                        const file = e.target.files ? e.target.files[0] : null;
                        const url = createLocalUrl(file);
                        handleItemChange('content', {file: file, url:url})
                    }}
                        />
                    )}

                    {newItem.type === 'Youtube' && (
                    <Form.Group controlId={`carouselYouTube`}>
                        <Form.Label>YouTube Embedded URL</Form.Label>
                        <Form.Control
                        type="text"
                        value={newItem.content || ''}
                        onChange={(e) => handleItemChange('content', e.target.value)}
                        placeholder="Enter YouTube embedded URL"
                        isInvalid={newItem.content && !isValidYouTubeEmbed(newItem.content)}
                        />
                        <Form.Control.Feedback type="invalid">
                        Please enter a valid YouTube embedded URL e.g., https://www.youtube.com/embed/VIDEO_ID (without query like ?si=...).
                        </Form.Control.Feedback>
                    </Form.Group>
                    )}

                    {newItem.type === 'iframe' && (
                    <Form.Group controlId={`carouselIframe`}>
                        <Form.Label>iFrame URL</Form.Label>
                        <Form.Control
                        type="text"
                        value={newItem.content || ''}
                        onChange={(e) => handleItemChange('content', e.target.value)}
                        placeholder="Enter iFrame URL"
                        />
                    </Form.Group>
                    )}

                    <div className='w-100 d-flex justify-content-center'>
                        <Button className='standard-button'
                        onClick={() => {setCurrentOperation(()=>addItem); setShowConfirmModal(true)}}
                        >
                            Add Item+
                        </Button>
                    </div>
                </Col>
            </Row>

            <ConfirmModal 
            show={showConfirmModal} 
            onHide={()=> {setShowConfirmModal(false); setErrMsg('');} }
            onConfirm={currentOperation}
            processing={processing}
            text="Are you sure of this action?"
            error={errMsg}
            >
            </ConfirmModal>
    </>);
}