import {useLocation} from "react-router-dom"

export function lerpColor(a, b, amount) { 

    var ah = +a.replace('#', '0x'),
        ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
        bh = +b.replace('#', '0x'),
        br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
        rr = ar + amount * (br - ar),
        rg = ag + amount * (bg - ag),
        rb = ab + amount * (bb - ab);
  
    return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
  }


  export function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export function detectWheelDirectionReact(event){
  if (event.deltaY>0){
    return 0
  }
  else{
    return 1
  }
}


export function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function detectLeftButton(event) {
  if ('buttons' in event) {
      return event.buttons === 1;
  } else if ('which' in event) {
      return event.which === 1;
  } else {
      return event.button === 1;
  }
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function detectSwipe(startX,endX){
  if (startX-endX>=30){
      return 0;  //swipe from right to left
  }
  else if(startX-endX<=-30){
      return 1; //swipe from left to right
  }
  else {
      return false;
  }
}


export function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}


export function sortObjectArray(array,property,descending=true){

    if(descending){
      array.sort((a,b)=>(a[property]>b[property]?1:-1));
    }
    else{
      array.sort((a,b)=>(a[property]>b[property]?-1:1));
    }
    

    return array;
}


export function detectEnterKeyPress(e){
    console.log(e.keycode);
}


export function createLocalUrl(file) {
  if(file){
      return URL.createObjectURL(file);
  }
  return null;
}

export const isValidYouTubeEmbed = (url) => {
  // Regex to match YouTube embed URLs without any query parameters
  const pattern = /^(https?:\/\/)?(www\.)?youtube\.com\/embed\/[a-zA-Z0-9_-]{11}$/;
  return pattern.test(url);
};