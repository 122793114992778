import React, { useContext, useState } from 'react';
import GlobalContext from '../global_context';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ConfirmModal from './confirm_modal';
import ModifyModal from './modify_modal';
import Cookies from 'universal-cookie';

import { API_UPDATE_INTERACTIVE_CATEGORY, API_DELETE_INTERACTIVE_CATEGORY, API_CREATE_INTERACTIVE_CATEGORY } from '../../config';


export default function CategoryManagementDropdown(props) {
    const { value, onChange } = props;
    const { interactiveCategories, refreshInteractiveCategories } = useContext(GlobalContext);
    const [ selectedCategory, setSelectedCategory ] = useState(value);
    const [processing, setProcessing] = useState(false);
    const [showModifyModal, setShowModifyModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [categoryToUpdate, setCategoryToUpdate] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const cookies = new Cookies();

    const handleSelect = (val) => {
        setSelectedCategory(val);
        onChange(val);
    };

    const createCategory = async (newCategoryName) => {
        setProcessing(true);

        try {
          const username = cookies.get('adminAccount');
          const pw = cookies.get('adminPassword');

          const formData = new FormData();
          
          formData.append('adminAccount', username);
          formData.append('adminPassword', pw);
          formData.append('new_category_name', newCategoryName);
          const response = await fetch(API_CREATE_INTERACTIVE_CATEGORY,
            {
              method: 'POST',
              body: formData,
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! ${response.status}`);
          }
      
          const data = await response.json();
          console.log('Message:', data.message);
          await refreshInteractiveCategories();
          setShowCreateModal(false);
          setErrorMsg('');
        } catch (error) {
          console.error('Failed to create category:', error);
          // Optionally, set an error state here
          setErrorMsg(error.toString());
        } finally{
          setProcessing(false);
        }     
    }

    const UpdateCategory = async (newCategoryName) => {
        setProcessing(true);

        try {
          const username = cookies.get('adminAccount');
          const pw = cookies.get('adminPassword');

          const formData = new FormData();
          
          formData.append('adminAccount', username);
          formData.append('adminPassword', pw);
          formData.append('original_category_name', categoryToUpdate);
          formData.append('new_category_name', newCategoryName);
          const response = await fetch(API_UPDATE_INTERACTIVE_CATEGORY,
            {
              method: 'POST',
              body: formData,
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! ${response.status}`);
          }
      
          const data = await response.json();
          console.log('Message:', data.message);
          console.log('All Interactives have also been updated');
          await refreshInteractiveCategories();
          setShowModifyModal(false);
          setErrorMsg('');
          if(selectedCategory === categoryToUpdate){
            setSelectedCategory(newCategoryName);
            onChange(newCategoryName);
          }
        } catch (error) {
          console.error('Failed to update category:', error);
          setErrorMsg(error.toString());
        } finally{
          
          setProcessing(false);
        }     
    };

    //remove category to update
    const RemoveCategory = async () => {
        setProcessing(true);

        try {
          const username = cookies.get('adminAccount');
          const pw = cookies.get('adminPassword');

          const formData = new FormData();
          
          formData.append('adminAccount', username);
          formData.append('adminPassword', pw);
          formData.append('delete_category_name', categoryToUpdate);
          const response = await fetch(API_DELETE_INTERACTIVE_CATEGORY,
            {
              method: 'POST',
              body: formData,
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! ${response.status}`);
          }
      
          const data = await response.json();
          console.log('Message:', data.message);
          await refreshInteractiveCategories();
          setShowRemoveModal(false);
          setErrorMsg('');
          if(selectedCategory === categoryToUpdate){
            setSelectedCategory('');
            onChange('');
          }
        } catch (error) {
          console.error('Failed to delete category:', error);
          setErrorMsg(error.toString());
        } finally{
          setProcessing(false);
        }
    };

    return (
        <>
            <Form.Group controlId="type">
                <Form.Label>Category</Form.Label>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DropdownButton
                        id="dropdown-basic-button"
                        title={selectedCategory || "Select Category"}
                        onSelect={handleSelect}
                        variant="outline-secondary" // Example of variant
                        size="lg" // Example of size
                        style={{ 
                          marginRight: '10px', 
                      }}
                    >
                        {interactiveCategories.map((option, index) => (
                            <Dropdown.Item key={index} eventKey={option}>
                                {option}
                                <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowModifyModal(true);
                                        setCategoryToUpdate(option);
                                    }}
                                    style={{ marginLeft: '10px' }}
                                >
                                    Modify
                                </Button>
                                <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowRemoveModal(true);
                                        setCategoryToUpdate(option);
                                    }}
                                    style={{ marginLeft: '10px' }}
                                >
                                    Remove
                                </Button>
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                    <Button variant="primary" 
                    onClick={() => {
                        setShowCreateModal(true);
                    }}
                    >Add+</Button>
                </div>
            </Form.Group>


            <ModifyModal
            show={showModifyModal}
            onHide={() => {
                setShowModifyModal(false);
                setErrorMsg('');
            }}
            title={`Modify Category: ${categoryToUpdate}`}
            initialText={categoryToUpdate}
            processing={processing}
            onConfirm={UpdateCategory}
            error={errorMsg}
            />

            <ModifyModal
              show={showCreateModal}
              onHide={() => {
                setShowCreateModal(false);
                setErrorMsg('');
              }}
              title='Add Category'
              initialText=''
              processing={processing}
              onConfirm={createCategory}
              error={errorMsg}
            />

            <ConfirmModal
              show={showRemoveModal}
              onHide={() => {
                setShowRemoveModal(false);
                setErrorMsg('');
              }}
              text={`Removing Category: ${categoryToUpdate}`}
              processing={processing}
              onConfirm={RemoveCategory}
              error={errorMsg}
            />
        </>
    );
}