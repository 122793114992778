import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

export default function Footer(props){


    return (
        <Row className="footer-section">
            <Col>
                <span className="footer">
                    Powered by <b>HOL Experiences</b>
                </span>    
            </Col>
        </Row>
    )
}