import React, { useEffect,useState, useContext } from 'react';
import {InteractiveData} from "./interactive_data"
import {Modal, Form, Row, Col, Button, InputGroup, Spinner} from 'react-bootstrap';
import UploadImageContainer from '../components/upload_image_container';
import {createLocalUrl} from '../utils/utils';
import ConfirmModal from '../components/confirm_modal';
import ModifyCarousel from './modify_carousel';
import Cookies from 'universal-cookie';
import CategoryManagementDropdown from '../components/category_management_dropdown';
import {API_UPDATE_INTERACTIVE} from "../../config"


//for testing purposes
const dummyData = {
    station_name: '',
    type: '',
    interactive_name: '',
    description: '',
    accessibility_features: '',
    assets_required: '',
    asset_details: [],
    gimme_carousel_items: []
}

export default function ModifyInteractive({interactiveId, show=true, onHide, interactive = dummyData, carouselItems=[], onUpdate}) {
    const [data, setData] = useState(null);
    const [processing, setProcessing] = useState(false);

    const [currentOperation, setCurrentOperation] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    //this will make the content retain in certain cases
    useEffect(()=>{
        if(!data){
            const transformedData = new InteractiveData({
                stationName: interactive.station_name,
                type: interactive.type,
                interactiveName: interactive.interactive_name,
                description: interactive.description,
                accessibilityFeatures: interactive.accessibility_features,
                assetsRequired: interactive.assets_required,
                assetDetails: interactive.asset_details,
                thumbnail: {file: null, url: null},
                previewGif: {file: null, url: null},
                gameImage: {file: null, url: null}
            });
            //console.log(transformedData);
            setData(transformedData);
        }
    }, [interactive]);

    useEffect(()=>{
        if(data){
          const clearedData = data;
          clearedData.thumbnail = {file: null, url: null};
          clearedData.previewGif = {file: null, url: null};
          clearedData.gameImage = {file: null, url: null};

          setData(clearedData);
        }

    }, [show])

    async function updateInteractive(){
        //console.log(API_UPDATE_INTERACTIVE);
        //console.log("Uploading data...");
        setProcessing(true);

        try {
          const cookies = new Cookies();

          const formData = new FormData();
          formData.append('adminAccount', cookies.get('adminAccount'));
          formData.append('adminPassword', cookies.get('adminPassword'));
          formData.append('id', interactiveId);
          formData.append('station_name', data.stationName);
          formData.append('type', data.type);
          formData.append('interactive_name', data.interactiveName);
          formData.append('description', data.description);
          formData.append('accessibility_features', data.accessibilityFeatures.join('||'));
          formData.append('assets_required', data.assetsRequired.join('||'));
          formData.append('asset_details', JSON.stringify(data.assetDetails));
          if(data.thumbnail)
            formData.append('thumbnail', data.thumbnail.file);
          if(data.previewGif)
            formData.append('preview_gif', data.previewGif.file);
          if(data.gameImage)
          formData.append('game_image', data.gameImage.file);

        
          const response = await fetch(API_UPDATE_INTERACTIVE, {
              method: 'POST',
              body: formData
          });
  
          if (!response.ok) {
              // Handle HTTP errors
              const errorText = await response.text();
              throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
          }
  
          const responseData = await response.json();
          console.log('Success:', responseData);
          onUpdate();
          setShowConfirmModal(false);
          onHide();
          // Handle success (e.g., show a success message, update state, etc.)
      } catch (error) {
          console.error('Error updating interactive:', error);
          setErrorMsg(error.message);
          // Handle error (e.g., show an error message to the user)
      } finally {
          setProcessing(false);
      }  
    }

    const updateData = (updatedData) => {
        setData(InteractiveData.fromObject(updatedData));
    };

return (
    <>
    <Modal size="xl"  show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>Update Interactive</Modal.Title>
    </Modal.Header>
    <Modal.Body>

    {!data ? <> </> :
        <Form>
        <Row>
          {/* <Col>
          <Form.Group controlId="thumbnailName">
          <Form.Label>Station Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter station name"
            value={data.stationName}
            onChange={(e) => updateData({ ...data, stationName: e.target.value })}
          />
        </Form.Group>
          </Col> */}
          <Col>
          <CategoryManagementDropdown
            value={data.type}
            onChange={(val) => updateData({ ...data, type: val })}
          />
          {/* <Form.Group controlId="type">
            <Form.Label>Category</Form.Label>

            <Form.Control
              as="select"
              value={data.type}
              onChange={(e) => updateData({ ...data, type: e.target.value })}
            >
                {interactiveCategories.map((option, index) => {
                  return <option key={index} value={option}>{option}</option>
                })}
            </Form.Control>
          </Form.Group> */}
          </Col>
        </Row>


        <Row>
            <Col xs={12}>
        <h3>Upload new images (Optional)</h3>
                
            </Col>
          <Col className='d-flex'>
        
            <UploadImageContainer 
            label="Thumbnail" 
            id="thumbnailImg" 
            accept='image/*' 
            imgData={data.thumbnail && data.thumbnail.url} 
            onChangeImg={(e) => {
                const file = e.target.files ? e.target.files[0] : null;
                const url = createLocalUrl(file);
                updateData({ ...data, thumbnail: {file: file, url: url} })
            }} />

            <UploadImageContainer 
            className='ml-5'
            label="Preview Gif" 
            id="previewGif" 
            accept='image/*' 
            imgData={data.previewGif && data.previewGif.url} 
            onChangeImg={(e) => {
                const file = e.target.files ? e.target.files[0] : null;
                const url = createLocalUrl(file);
                updateData({ ...data, previewGif: {file: file, url: url} })
            }} />

            </Col>
        </Row>

      <Form.Group controlId="interactiveName">
        <Form.Label>Interactive Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter interactive name"
          value={data.interactiveName}
          onChange={(e) => updateData({ ...data, interactiveName: e.target.value })}
        />
      </Form.Group>

      <Form.Group controlId="description">
        <Form.Label>What is this about?</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Describe the content"
          value={data.description}
          onChange={(e) => updateData({ ...data, description: e.target.value })}
        />
      </Form.Group>

      <Row>
        <Col>
          <Form.Group controlId="accessibilityFeatures">
            <Form.Label>Accessibility Features</Form.Label>
            {data.accessibilityFeatures.map((feature, index) => (
            <InputGroup key={index} className="mb-2">
              <Form.Control
                type="text"
                value={feature}
                onChange={(e) => {
                  const updatedFeatures = [...data.accessibilityFeatures];
                  updatedFeatures[index] = e.target.value;
                  updateData({ ...data, accessibilityFeatures: updatedFeatures });
                }}
                placeholder="Enter accessibility feature"
              />
              <InputGroup.Append>
                <Button
                  variant="danger"
                  onClick={() => {
                    data.removeFeature(index);
                    updateData({ ...data });
                  }}
                >
                  Remove
                </Button>
              </InputGroup.Append>
            </InputGroup>
          ))}
          <br/>
          <Button
            variant="primary"
            onClick={() => {
              data.accessibilityFeatures.push('')
              updateData({ ...data });
            }}
          >
            Add Feature+
          </Button>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="assetsRequired">
          <Form.Label>Assets Required</Form.Label>
          {data.assetsRequired.map((asset, index) => (
            <InputGroup key={index} className="mb-2">
              <Form.Control
                type="text"
                value={asset}
                onChange={(e) => {
                  const updatedAssets = [...data.assetsRequired];
                  updatedAssets[index] = e.target.value;
                  updateData({ ...data, assetsRequired: updatedAssets });
                }}
                placeholder="Enter required asset"
              />
            <InputGroup.Append>
              <Button
                variant="danger"
                onClick={() => {
                  data.removeAsset(index);
                  updateData({ ...data });
                }}
              >
                Remove
              </Button>
            </InputGroup.Append>
          </InputGroup>
        ))}
        <br/>
        <Button
          variant="primary"
          onClick={() => {
            data.addAsset('');
            updateData({ ...data });
          }}
        >
          Add Asset+
        </Button>
        </Form.Group>
      </Col>
    </Row>
      
    <Row>
    <Col>
      <Form.Group controlId={`assetDetails`}>
      <Form.Label>Assets Descriptions</Form.Label>
          <Row>
          {data.assetDetails.map((asset, index) => {
            return (
              <Col xs={6} xl={4} key={`asset-info-${index}`}>
                <InputGroup className="mb-2 d-flex flex-column w-100">
                  
                    <Form.Control
                      className='w-100'
                      type="text"
                      value={asset.name}
                      onChange={(e) => {
                        const updatedAssets = [...data.assetDetails];
                        updatedAssets[index] = { ...updatedAssets[index], name: e.target.value };
                        updateData({ ...data, assetDetails: updatedAssets });
                      }}
                      placeholder={`Enter asset name`}
                    />
                    <Form.Control
                      className='w-100 mt-2'
                      type="text"
                      as="textarea"
                      value={asset.description}
                      onChange={(e) => {
                        const updatedAssets = [...data.assetDetails];
                        updatedAssets[index] = { ...updatedAssets[index], description: e.target.value };
                        updateData({ ...data, assetDetails: updatedAssets });
                      }}
                      placeholder={`Enter description`}
                    />
                  

                  <InputGroup.Append>
                  <Button
                    className='mt-2'
                    variant="danger"
                    onClick={() => {
                      data.removeAssetDetail(index);
                      updateData({ ...data });
                    }}
                  >
                    Remove
                  </Button>
                </InputGroup.Append>
                </InputGroup>
              </Col>
            );
          })}
          </Row>


          <Button
            variant="primary"
            onClick={() => {
              data.addAssetDetail({name: '', description: ''});
              updateData({ ...data });
            }}
          >
            Add Asset Description+
          </Button>

        </Form.Group>
        </Col>

        <Col xs={12}>
        <UploadImageContainer 
                label="Gimme Instruction Image" 
                id="gameImg" 
                accept='image/*' 
                imgData={data.gameImage && data.gameImage.url} 
                onChangeImg={(e) => {
                  const file = e.target.files ? e.target.files[0] : null;
                  const url = createLocalUrl(file);
                  updateData({ ...data, gameImage: {file: file, url: url} })
                }} />
        </Col>
      </Row>
      </Form>
    }


      {processing ? (
        <div className="d-flex align-items-center">
          <Spinner animation="grow" variant="secondary" role="status" size="sm" className="mr-2">
            <span className="sr-only">Processing...</span>
          </Spinner>
          <span>Processing...</span>
        </div>
      ) : (
        <div className='w-100 justify-content-center d-flex'>
            <Button className='standard-button'  onClick={()=>{setCurrentOperation(()=>updateInteractive); setShowConfirmModal(true)}}>
                Update Interactive
            </Button>
        </div>
      )}
    </Modal.Body>

    <Modal.Header>
        <Modal.Title>Update Carousel</Modal.Title>
    </Modal.Header>
    <Modal.Body>

      <ModifyCarousel 
          ownerType="gimme" 
          ownerId={interactiveId}
          carouselItems={carouselItems}
          onCarouselUpdate={onUpdate}
          />

    </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        </Modal.Footer>
  </Modal>

  <ConfirmModal 
  show={showConfirmModal} 
  onHide={()=>{setShowConfirmModal(false); setErrorMsg('');}} 
  onConfirm={currentOperation}
  text="This operation is irreversible. Are you sure you want to proceed?"
  processing={processing}
  error={errorMsg}
  ></ConfirmModal>
  </>
);
}
