import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

export default function ConfirmModal({ show, onHide, onConfirm, text, processing = false, error = "" }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {text}
        
      </Modal.Body>
      <Modal.Footer>
        {processing ? (
          <div className="d-flex align-items-center">
            <Spinner animation="grow" variant="secondary" role="status" size="sm" className="mr-2">
              <span className="sr-only">Processing...</span>
            </Spinner>
            <span>Processing...</span>
          </div>
        ) : (
          <>
          <div className="d-flex flex-column align-items-end">
              <div>
                <Button variant="secondary" onClick={onHide}>
                  Cancel
                </Button>
                <Button className="ml-3" variant="danger" onClick={onConfirm}>
                  Confirm
                </Button>
              </div>
              {error && <div className="text-danger mt-3">{error}</div>}
            </div>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}