import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

export default function ModifyCategoryModal({ title, initialText, processing, onConfirm, show, onHide, error='' }) {
    const [text, setText] = useState('');
    useEffect(() => {
        if(show){
            setText(initialText);
        }
            
    }, [initialText, show]);

    const handleConfirm = () => {
        onConfirm(text);
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formCategoryText">
                        <Form.Label>Update Category Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {processing ? (
                <div className="d-flex align-items-center">
                    <Spinner animation="grow" variant="secondary" role="status" size="sm" className="mr-2">
                    <span className="sr-only">Processing...</span>
                    </Spinner>
                    <span>Processing...</span>
                </div>
                ) : (
                <>
                    <div className="d-flex flex-column align-items-end">
                        <div>
                            <Button variant="secondary" onClick={onHide} disabled={processing}>
                            Cancel
                            </Button>
                            <Button className="ml-3" variant="danger" onClick={handleConfirm} disabled={processing}>
                            Confirm
                            </Button>
                        </div>
                        {error && <div className="text-danger mt-3">{error}</div>}
                    </div>
                </>
                )}

            </Modal.Footer>
        </Modal>
    );
}