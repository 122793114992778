
const CarouselItemTypes = [
    'image','Youtube', 'iframe'
]

class ClientData {
    constructor(
        name = '', 
        logo = null,
        targetAudience = '', 
        templateUsage = '', 
        timeToDevelop = { value: 0, unit: '' }, 
        dwellTime = { value: 0, unit: '' }, 
        interactionsRegistered = 0,
        carousel = [], // [CarouselItemType, data]
    ) {
      this.name = name;
      this.logo = logo; // File object
      this.carousel = carousel; // Array of carousel items
      this.targetAudience = targetAudience;
      this.templateUsage = templateUsage;
      this.timeToDevelop = timeToDevelop; // { value: number, unit: string }
      this.dwellTime = dwellTime; // { value: number, unit: string }
      this.interactionsRegistered = interactionsRegistered; // percentage
    }
    

    static fromObject(obj) {
      return new ClientData(
        obj.name,
        obj.logo,
        obj.targetAudiences,
        obj.templateUsage,
        obj.timeToDevelop,
        obj.dwellTime,
        obj.interactionsRegistered,
        obj.carousel,
      );
    }
  }
  
  class InteractiveData {
    constructor({
      stationName = '',
      type = '',
      thumbnail = { file: null, url: null },
      previewGif = { file: null, url: null },
      interactiveName = '',
      description = '',
      accessibilityFeatures = [],
      assetsRequired = [],
      carousel = [],  // [CarouselItemType, data]
      clients = [],
      assetDetails = [],
      gameImage = { file: null, url: null },
    } = {}) {
      this.stationName = stationName;
      this.type = type;
      this.thumbnail = thumbnail; // File object
      this.previewGif = previewGif; // File object
      this.interactiveName = interactiveName;
      this.description = description;
      this.accessibilityFeatures = accessibilityFeatures; // Array of strings
      this.assetsRequired = assetsRequired; // Array of strings
      this.carousel = carousel; // Array of carousel items
      this.clients = clients.map(client => ClientData.fromObject(client)); // Array of ClientData objects
      this.assetDetails = assetDetails; // Array of objects
      this.gameImage = gameImage; 
    }
  
    static fromObject(obj) {
      return new InteractiveData({
          stationName: obj.stationName,
          type: obj.type,
          thumbnail: obj.thumbnail,
          previewGif: obj.previewGif,
          interactiveName: obj.interactiveName,
          description: obj.description,
          accessibilityFeatures: obj.accessibilityFeatures,
          assetsRequired: obj.assetsRequired,
          carousel: obj.carousel,
          clients: obj.clients,
          assetDetails: obj.assetDetails,
          gameImage: obj.gameImage
      });
    }
  
    // CRUD-related functions
    addClient(client) {
      this.clients.push(client);
    }

    updateClient(index, updatedClient) {
      if (index >= 0 && index < this.clients.length) {
        this.clients[index] = updatedClient;
      }
    }
  
    removeClient(index) {
      if (index >= 0 && index < this.clients.length) {
        this.clients.splice(index, 1);
      }
    }
  
    addFeature(feature) {
      this.accessibilityFeatures.push(feature);
    }
  
    removeFeature(index) {
      if (index >= 0 && index < this.accessibilityFeatures.length) {
        this.accessibilityFeatures.splice(index, 1);
      }
    }
  
    addAsset(asset) {
      this.assetsRequired.push(asset);
    }

    addAssetDetail(assetInfo) {
      this.assetDetails.push(assetInfo);
    }
  
    removeAsset(index) {
      if (index >= 0 && index < this.assetsRequired.length) {
        this.assetsRequired.splice(index, 1);
      }
    }

    removeAssetDetail(index) {
      if (index >= 0 && index < this.assetDetails.length) {
        this.assetDetails.splice(index, 1);
      }
    }

    createFormData(){
        const formData = new FormData();
        formData.append('station_name', this.stationName);
        formData.append('type', this.type);
        if(this.thumbnail)
            formData.append('thumbnail', this.thumbnail.file);
        if(this.previewGif)
            formData.append('preview_gif', this.previewGif.file);
        formData.append('interactive_name', this.interactiveName);
        formData.append('description', this.description);
        formData.append('accessibility_features', this.accessibilityFeatures.join('||'));
        formData.append('assets_required', this.assetsRequired.join('||'));
        
        formData.append('asset_details', JSON.stringify(this.assetDetails));
        if(this.gameImage)
          formData.append('game_image', this.gameImage.file);

        this.carousel.forEach((item, index) => {
            const key = `carousel[${index}]`;
            formData.append(`${key}[url_type]`, item.type);
            if (item.type === 'image') {
              formData.append(`${key}[content]`, item.content && item.content.file ? item.content.file : null);
            } else {
              formData.append(`${key}[content]`, item.content);
            }
        });

        //Due to how PHP $FILES_, we cannot send an array of objects, so we need to flatten the object
        //and each client object will store offset for the carousel items
        let carouselImageCounter = 0;
        this.clients.forEach((client, index) => {
            const clientKey = `clients[${index}]`;
            formData.append(`${clientKey}[name]`, client.name);
            formData.append(`${clientKey}[logo]`, client.logo && client.logo.file  ? client.logo.file : null);
            formData.append(`${clientKey}[target_audience]`, client.targetAudience);
            formData.append(`${clientKey}[template_usage]`, client.templateUsage);
            formData.append(`${clientKey}[develop_time]`, joinWithSeparator(client.timeToDevelop.value, client.timeToDevelop.unit));
            formData.append(`${clientKey}[dwell_time]`, joinWithSeparator(client.dwellTime.value, client.dwellTime.unit));
            formData.append(`${clientKey}[interactions_registered]`, client.interactionsRegistered);
            client.carousel.forEach((item, index) => {
              formData.append(`${clientKey}[carousel][${index}][url_type]`, item.type);
                
                if (item.type === 'image') {
                  const carouselKey = `client_carousel[${carouselImageCounter}]`;
                  if (item.content && item.content.file) {
                    formData.append(`${carouselKey}`, item.content.file);
                    ++carouselImageCounter;
                } else {
                    formData.append(`${carouselKey}`, null);
                }
                } else {
                  const carouselKey = `${clientKey}[carousel][${index}]`;
                  formData.append(`${carouselKey}[content]`, item.content);
                }
                
            });
        });
        return formData;
    }
  }

  function joinWithSeparator(a, b, separator='||') {
    if(a === '' || b === '') return '';
    return `${a}${separator}${b}`;
  }

  export {ClientData, InteractiveData, CarouselItemTypes, joinWithSeparator};