import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { hol_logo_colored } from '../../static/images';
// import {SlideDown} from 'react-slidedown'
// import 'react-slidedown/lib/slidedown.css'
import { Link } from 'react-router-dom';

//mobile version
import { bool } from 'prop-types';

SideBar.propTypes = {
  open: bool.isRequired,
};

export default function SideBar(props) {
  const { open, setOpen } = props;

  const [adminOne, setAdminOne] = useState(false);
  const [adminTwo, setAdminTwo] = useState(false);
  const [adminThree, setAdminThree] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    const a1 = cookies.get('a1');
    const a2 = cookies.get('a2');
    const a3 = cookies.get('a3');

    setAdminOne(a1);
    setAdminTwo(a2);
    setAdminThree(a3);
  }, [open]);
  // function hideAll() {
  //   setOpen(false);
  // }

  return (
    <>
      <div
        className='menu-sidebar'
        open={open}
        style={{ transform: open ? 'translateX(0)' : 'translateX(-100%)' }}
      >
        <div className='menu-overlay' onClick={() => setOpen(!open)}></div>
        <div className='menu-content' style={{ transform: open ? 'translateX(0)' : 'translateX(-100%)' }}>
          <div className='menu-item logo_container'>
            <Link to="/home" onClick={()=>setOpen(false)}>
            <img className='logo' alt='logo' src={hol_logo_colored} width='100' ></img>
            </Link>
          </div>

          <div className='menu-item'>
            <div className='menu-item-header'>HOL Gallery</div>
          </div>

          <div className='menu-item'>
            {/* <Link className='nav-link' to={{ pathname: '/home' }}>
              View all interactives
            </Link> */}
            {/* <Link className='nav-link' to={{ pathname: '/home/gimme' }}>
              View all GIMME
            </Link> */}
            
            <Link className="nav-link" 
            to={{pathname: '/new-upload'}} 
            style={{display: adminOne? "" : "none"}}
            onClick={()=>setOpen(false)}
            >Add new interactive</Link>
          </div>

          <div className='menu-item' style={{ display: adminTwo ? '' : adminThree ? '' : 'none' }}>
            <div className='menu-item-header'>Account Management</div>
          </div>

          <div className='menu-item' style={{ display: adminTwo ? '' : adminThree ? '' : 'none' }}>
            <Link
              className='nav-link'
              to={{ pathname: '/client_accounts' }}
              style={{ display: adminTwo ? '' : 'none' }}
              onClick={()=>setOpen(false)}
            >
              Manage client accounts
            </Link>
            <Link
              className='nav-link'
              to={{ pathname: '/admin_accounts' }}
              style={{ display: adminThree ? '' : 'none' }}
              onClick={()=>setOpen(false)}
            >
              Manage admin accounts
            </Link>

          </div>

          {/* <div className="footer copyright">
                    <span className="bold">&#169; Give Dangerously 2021</span>
                </div> */}

          <div className='footer-wrapper'>
            <div className='footer'>
              Powered by{' '}
              <a href='https://www.hol.sg/' target='_blank' rel='noopener noreferrer'>
                <span className='bold' style={{ color: '#c3d600' }}>
                  HOL Experiences
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
