import React from 'react';

const CenterContainer = ({ children, className = '', style = {} }) => {
    // Ensure only one child is passed
    const child = React.Children.only(children);
    return (
        <div
            className={`full-width-padded  ${className}`.trim()}
            style={style}
        >
            {React.cloneElement(child, {
                className: `${child.props.className || ''} side-padding`.trim()
            })}
        </div>
    );
};

export default CenterContainer;