import React from "react";

import Container from "react-bootstrap/Container"
import Footer from "../components/Footer";

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import error_img from "../../static/images/general/error404.png"

//not used?
export default function NotFound(props){
    const {} = props;

    return (
    <>
        <div className="admin-container">
            <Container fluid>

                    <div className="error-container">
                        <Row>
                            <Col xs={12}>
                                <Row>
                                    <div className="error-content">
                                        <h2 className="header">Error</h2>
                                        <img src={error_img} className="error-img" alt="Page not found"/>
                                        <p>Page not found.</p>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                <Footer />
            </Container>
        </div>
    </>
    )
}