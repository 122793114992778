import React, { useState, useEffect } from 'react';

export default function DropdownCheckbox(props) {
  const {
    options,
    menuNames,
    onCheck,
    onUncheck,
    menuText,
    isRadio = false,
    selected = undefined,
    className,
    disable,
    search = false,
  } = props;

  const [isFocus, setIsFocus] = useState(false);
  const [checkState, setCheckState] = useState(isRadio ? undefined : []);

  const [dropdownItems, setDropdownItems] = useState([]);

  const [menuName, setMenuName] = useState(menuNames ? menuNames[0] : false);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    let value = searchTerm.toLocaleLowerCase();
    let results = [];
    if (dropdownItems) {
      results = dropdownItems.filter((data) => {
        return data.key.toLocaleLowerCase().search(value) != -1;
      });
    } else {
      results = [];
    }

    setSearchResults(results);
  }, [searchTerm, dropdownItems]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function onClickItem(index) {
    if (!isRadio) {
      if (checkState[index] === false) {
        onCheck(index);
      } else {
        onUncheck(index);
      }
      let newCheckstate = [...checkState];
      newCheckstate[index] = !newCheckstate[index];
      setCheckState(newCheckstate);
    } else {
      onCheck(index);
      setCheckState(index);
      setMenuName(menuNames ? menuNames[index] : false);
    }
  }

  function onClickDropdown() {
    if (!disable) {
      if (isFocus) {
        setIsFocus(false);
      } else {
        setIsFocus(true);
      }
    }
  }

  useEffect(() => {
    if (options) {
      if (!isRadio) {
        setCheckState(options.map(() => false));
      } else {
        setCheckState(0);
      }
    }
  }, [options]);

  useEffect(() => {
    if (!isRadio) {
      //can select multiple items
      if (checkState.length > 0) {
        const items = options.map((v, i) => {
          const value = v;
          const index = i;
          return (
            <div
              key={`item-${index}-${value}`}
              className={`item ${checkState[index] ? 'checked' : ''}`}
              onClick={() => {
                onClickItem(index);
              }}
            >
              <div className='checkbox'>
                <i className='fas fa-check'></i>
              </div>
              <div className='item-name'>{value}</div>
            </div>
          );
        });
        setDropdownItems(items);
      }
    } else {
      //can only select one item
      if (checkState !== undefined) {
        const items = options.map((v, i) => {
          const value = v;
          const index = i;
          return (
            <div
              key={`item-${index}-${value}`}
              className={`item ${checkState === i ? 'checked' : ''}`}
              onClick={() => {
                onClickItem(index);
              }}
            >
              <div className='checkbox'>
                <i className='fas fa-check'></i>
              </div>
              <div className='item-name'>{value}</div>
            </div>
          );
        });
        setDropdownItems(items);
      }
    }
  }, [checkState]);

  useEffect(() => {
    if (selected !== undefined && options.length > 0) {
      let newCheckState = [];
      for (let i = 0; i < options.length; i++) {
        newCheckState.push(false);
      }

      for (let j = 0; j < selected.length; j++) {
        newCheckState[selected[j]] = true;
      }

      setCheckState(newCheckState);
    }
  }, [selected, options]);

  return (
    <div
      tabIndex='0'
      onBlur={() => {
        setIsFocus(false);
      }}
      className={`${className} dropdown-checkbox-wrapper`}
      style={{ display: disable ? 'none' : '' }}
    >
      <div
        className='toggle'
        onClick={() => {
          onClickDropdown();
        }}
      >
        {search === true && (
          <input
            style={{ border: '0px', width: '35%', outline: 'none' }}
            className='text'
            placeholder='Search...'
            onChange={handleChange}
            value={searchTerm}
          ></input>
        )}
        <div style={{ whiteSpace: 'nowrap' }} className='text'>
          {menuText}&nbsp;{menuNames ? menuName : ''}
        </div>
        <i className={`sort-icon fas ${isFocus ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
      </div>

      <div
        className='menu'
        style={{
          display: isFocus ? 'block' : 'none',
         // overflow: 'scroll',
          maxHeight: '250px',
          paddingBottom: '20px',
        }}
      >
        {searchResults}
      </div>
    </div>
  );
}
