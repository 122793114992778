export const hol_logo_colored=require("./images/general/hol_logo_colored.png")

export const half_diamond=require("./images/general/half_diamond.png");

export const white_cross=require('./images/general/white_cross.png')
export const black_cross=require('./images/general/black_cross.png')

export const loading_gif=require("./images/general/loading.gif")

export const persona=require("./images/login/persona.png")
export const lock=require("./images/login/lock.png")

export const magnifier=require("./images/gallery/magnifier.png")
export const phone_frame=require("./images/gimme/phone_frame.png")